import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

import OnlyIconBtn from 'components/Buttons/OnlyIconBtn';
import { ExternalLinkIcon } from '@heroicons/react/solid';
import RadixTooltip from 'components/Tooltips/RadixTooltip';

import { epochToReadable } from 'utils/unitConversion';
import LogGrid from 'components/Grids/ModelGrids/LogGrid';

import { getModelVersionsAction } from 'redux/actions/model';
import { getTasksAction } from 'redux/actions/task';

const VersionModal = ({ activeModel }) => {
  const [versions, setVersions] = useState([]);
  const [tasks, setTasks] = useState([]);
  const dispatch = useDispatch();

  const getVersions = async () => {
    const result = await dispatch(getModelVersionsAction(activeModel.id)).unwrap();
    setVersions(result);
  };
  const getTasks = async () => {
    const result = await dispatch(getTasksAction({ object_type: 'Datamodel', object_id: activeModel.id })).unwrap();
    setTasks(result);
  };

  useEffect(() => {
    getVersions();
  }, []);

  useEffect(() => {
    getTasks();
  }, []);

  let tabs = [
    { id: '1', label: 'Jobs' },
    { id: '2', label: 'Versions' },
    { id: '3', label: 'Errors' },
  ];

  let [activeTab, setActiveTab] = useState(tabs[0].id);

  return (
    <div className="p-3 max-h-[82.5vh] h-[82.5vh] overflow-hidden ">
      <div className="mx-auto flex space-x-1 mb-3 bg-zinc-100 border border-zinc-300 p-1 rounded-3xl w-fit">
        {tabs.map((tab) => (
          <button
            key={tab.id}
            onClick={() => setActiveTab(tab.id)}
            className={`${activeTab === tab.id ? 'text-zinc-50' : 'hover:text-zinc-700/60'
              } relative rounded-full px-3 py-1.5 text-sm font-medium  transition z-20 `}
          >
            {activeTab === tab.id && (
              <motion.span
                layoutId="bubble"
                className="absolute rounded-2xl inset-0 -z-10 bg-zinc-600 "
                transition={{ type: 'spring', bounce: 0.2, duration: 0.6 }}
              />
            )}
            {tab.label}
          </button>
        ))}
      </div>
      <div className="h-[1px] w-full bg-zinc-200 mb-3" />
      {activeTab == '1' && (
        <div className="overflow-scroll h-[87.5%]">
          <LogGrid log={tasks} model={activeModel} />
        </div>
      )}
      {activeTab == '2' && (
        <div className=" py-4 px-6 overflow-scroll h-[87.5%]">
          <ul role="list" className="-mb-8">
            {versions?.map((version, index) => (
              <li key={index}>
                <div className="relative pb-8">
                  {index !== versions.length - 1 ? (
                    <span
                      className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200"
                      aria-hidden="true"
                    />
                  ) : null}
                  <div className="relative items-center flex space-x-3">
                    <div>
                      <span
                        className={`${version.is_current_version == 1
                          ? 'bg-green-200 border-green-600 text-green-600'
                          : 'bg-zinc-200 text-zinc-600 border-zinc-400'
                          } h-8 w-8 rounded-full text-xs font-bold border tracking-wide flex items-center justify-center ring-8 ring-zinc-50`}
                      >
                        {version.version}
                      </span>
                    </div>
                    <div className="  items-center flex min-w-0 flex-1 justify-between space-x-4 ">
                      <div>
                        <p className="text-sm font-medium text-zinc-700">
                          {version.user.name}
                          <a className="font-normal text-zinc-500">
                            {' '}
                            {version.is_current_version == 1 ? (
                              <span>
                                saved the{' '}
                                <a className="underline decoration-[1.5px] underline-offset-2	decoration-zinc-400">
                                  current
                                </a>{' '}
                                version {version.version}
                              </span>
                            ) : index == versions.length - 1 ? (
                              'created the first version of this model'
                            ) : (
                              'saved a new version'
                            )}
                          </a>
                        </p>
                        <p className="text-xs  text-zinc-500">
                          {epochToReadable(version.created_at)}
                        </p>
                      </div>

                      <div className="whitespace-nowrap text-right text-sm text-zinc-500">
                        <RadixTooltip tooltipText={`Open version ${version.version}`}>
                          <OnlyIconBtn
                            onClick={() =>
                              window.open(
                                `/modelling/edit/${version.datamodel_id}/${version.id}`,
                                '_blank'
                              )
                            }
                            icon={<ExternalLinkIcon className="text-zinc-500 h-4 w-4" />}
                          />
                        </RadixTooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
      {activeTab == '3' && (
        <div className="overflow-scroll h-[87.5%]">
          <LogGrid log={tasks.filter((task) => task.run_code === 500)} model={activeModel} />
        </div>
      )}

      {/* <Table {...{ headers, tableBody }} /> */}
    </div>
  );
};

VersionModal.propTypes = {
  activeModel: PropTypes.object,
  onClose: PropTypes.func,
  kpis: PropTypes.array,
};

VersionModal.defaultProps = {
  activeModel: {},
  onClose: null,
  kpis: [],
};

export default VersionModal;

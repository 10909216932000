import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAllDatabasesService, getDatabaseService, deleteDatabaseService, getDatabaseWhitelistService, createDatabaseService, updateWhitelistService } from 'services/database';

// First, create the thunk
export const getAllDatabasesAction = createAsyncThunk('databases/fetch', async () => {
  const response = await getAllDatabasesService();
  return response;
});

export const getDatabaseAction = createAsyncThunk('databases/get', async (databaseId) => {
  const response = await getDatabaseService(databaseId);
  return response;
});

export const deleteDatabaseAction = createAsyncThunk('database/delete', async (databaseId) => {
  const response = await deleteDatabaseService(databaseId);
  return response;
});

export const getDatabaseWhitelistAction = createAsyncThunk('', async (databaseId) => {
  const response = await getDatabaseWhitelistService(databaseId);
  return response;
});

export const createDatabaseAction = createAsyncThunk(
  'database/create',
  async ({ data }) => {
    const response = await createDatabaseService(data);
    return response;
  }
);

export const updateWhitelistAction = createAsyncThunk(
  'whitelist/update',
  async ({ id, data }) => {
    const response = await updateWhitelistService(id, data);
    return response;
  }
);
// Then, handle actions in your reducer

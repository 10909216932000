import React, { useEffect, useState } from 'react';
{
  /* Add useRef to add description */
}

import { useDispatch, useSelector } from 'react-redux';
import {
  getAllTablesAction,
  metadataTableAction,
  previewTableAction,
  deleteTableAction,
} from 'redux/actions/table';
import Heading from 'components/Layout/PageHeading';

import DeleteModal from 'components/Modals/DeleteModal';
import {
  CubeIcon,
  DatabaseIcon,
  DocumentReportIcon,
  // DownloadIcon,
  EyeIcon,
  TrashIcon,
  XIcon,
} from '@heroicons/react/solid';
import OnlyIconBtn from 'components/Buttons/OnlyIconBtn';
import { TableIcon } from '@heroicons/react/outline';
import { addThousandSeparators } from 'utils/unitConversion';
import SearchInput from 'components/Inputs/SearchInput';
import BasicModal from 'components/Modals/BasicModal';
import DataCatalogueRow from 'components/Cards/DataCatalogueCard';
import DataTable from 'components/Modelling/DataTable';
import ModalHeader from 'components/Layout/ModalHeader';
import FormatIcon from 'components/Badges/FormatIcon';

export default function DataCatalogueV2() {
  const dispatch = useDispatch();

  const { tables } = useSelector(({ tables }) => {
    return {
      tables: tables.tables,
    };
  });

  const [openDelete, setDeleteModal] = useState(false);
  const [openPreview, setPreviewModal] = useState(false);
  const [openMetadata, setMetadataModal] = useState(false);

  const [previewData, setpreviewData] = useState([]);
  const [metadataData, setMetadataData] = useState([]);
  const [activeTable, setActiveTable] = useState(null);
  const [filteredTables, setFilteredTables] = useState([]);


   const updateFilteredTables = () => {
    // Update filteredTables whenever tables changes
    setFilteredTables(tables);
  };

  useEffect(updateFilteredTables, [tables]);

  useEffect(() => {
    function componentDidMount() {
      dispatch(getAllTablesAction());
    }
    componentDidMount();
  }, []);

  const deleteTableHandler = (object) => {
    return deleteTableActionHandler(object);
  };

  const deleteTableActionHandler = async (object) => {
    const payload = { object_type: object.source_type, object_id: object.source_id, table_name: object.table_name };
    await dispatch(deleteTableAction(payload));
    await dispatch(getAllTablesAction());
    setDeleteModal(false);
    setActiveTable(null);
  };

  useEffect(() => {
    dispatch(getAllTablesAction());
  }, []);

 

  function deleteTable() {
    setDeleteModal(true);
  }

  const previewTableHandler = async () => {
    const result = await dispatch(
      previewTableAction({ object_type: activeTable.source_type, object_id: activeTable.source_id, table_name: activeTable.table_name })
    );
    setPreviewModal(true);
    setpreviewData(result.payload);
  };

  const metadataTableHandler = async () => {
    setMetadataModal(true);
    const result = await dispatch(
      metadataTableAction({ object_type: activeTable.source_type, object_id: activeTable.source_id, table_name: activeTable.table_name })
    );
    setMetadataData(result.payload);
  };

  function setActive(object) {
    if (activeTable === object) {
      setActiveTable(null);
    } else {
      setActiveTable(object);
    }
  }

  

  const handleSearch = (searchValue) => {
    
    const lowerCaseSearch = searchValue.toLowerCase();

    const newTables = tables.filter((table) => {
        const tableName = table.table_name.toLowerCase();
        
        return tableName.includes(lowerCaseSearch);
    });
      
    setFilteredTables(newTables);
};




  const getFilteredMetadata = () => {
    if (metadataData.length === 0) {
      return []; // Return an empty array if metadataData is empty
    }

    return metadataData.map(({ 'Column Name': columnName, 'Data Type': dataType }) => {
      let updatedDataType = dataType; // Store the updated data type

      // Conditionally modify the data type based on its original value
      if (dataType === 'double') {
        updatedDataType = (
          <>
            <div className="my-1 flex flex-row items-center">
              <FormatIcon format={"Decimal (Float)"} />
              <p className="ml-2">Decimal number (double)</p>
            </div>
          </>
        );
      } else if (dataType === 'text') {
        updatedDataType = (
          <>
            <div className="my-1 flex flex-row items-center">
              <FormatIcon format={"String"} />
              <p className="ml-2">Text (string)</p>
            </div>
          </>
        );
      } else if (dataType === 'datetime') {
        updatedDataType = (
          <>
            <div className="my-1 flex flex-row items-center">
              <FormatIcon format={"Datetime"} />
              <p className="ml-2">Date (datetime)</p>
            </div>
          </>
        );
      } else if (dataType === 'bigint') {
        updatedDataType = (
          <>
            <div className="my-1 flex flex-row items-center">
              <FormatIcon format={"Decimal (Float)"} />
              <p className="ml-2">Whole number (integer)</p>
            </div>
          </>
        );
      } else if (dataType === 'bool') {
        updatedDataType = (
          <>
            <div className="my-1 flex flex-row items-center">
              <FormatIcon format={"Boolean"} />
              <p className="ml-2">Binary (boolean)</p>
            </div>
          </>
        );
      } else if (dataType === 'object') {
        {
          updatedDataType = (
            <>
              <div className="my-1 flex flex-row items-center">
                <FormatIcon format={"String"} />
                <p className="ml-2">String</p>
              </div>
            </>
          );
        }
      } else {
        updatedDataType = (
          <>
            <div className="my-1 flex flex-row items-center">
              <FormatIcon format={"Unknown"} />
              <p className="ml-2">Unknown</p>
            </div>
          </>
        );
      }

      return {
        Column: columnName,
        'Data Type': updatedDataType,
      };
    });
  };

  const filteredMetadata = getFilteredMetadata();
  return (
    <div className="h-auto w-auto mx-auto m-3 rounded-lg p-3 overflow-hidden">
      <div className="mb-2">
        <Heading header="Catalogue" />
      </div>
      <div className="w-4/12 h-10 my-5 flex flex-row gap-3 ">
        <SearchInput onChange={handleSearch} placeholder="Search tables.." />
      </div>
      <div
        className={`mt-3 max-h-[calc(100vh_-_150px)] min-h-[calc(50vh)] ${filteredTables.length == 0 ? 'h-[calc(50vh)]' : ''
          } rounded overflow-hidden border border-zinc-200 dark:border-zinc-600 flex flex-row`}
      >
        <div className="overflow-scroll scroll-pr-10 border-r border-zinc-100 dark:border-zinc-600 bg-zinc-50 dark:bg-zinc-800 max-w-5/12 w-5/12">
          {filteredTables.length == 0 && (
            <div className="justify-center items-center flex h-full flex-col">
              <p className="text-zinc-700 dark:text-zinc-50 text-base -mt-4">No tables</p>
              <p className="text-zinc-300 dark:text-zinc-400 text-sm font-light mt-1">
                Adjust your search input or dropdowns
              </p>
            </div>
          )}
          {filteredTables.map((table, index) => (
            <div
              onClick={() => setActive(table)}
              className={`${index + 1 == filteredTables.length && filteredTables.length == 1
                ? 'border-b'
                : index == 0
                  ? ''
                  : 'border-t'
                } pl-3 pr-10 py-1.5 items-center flex h-20  ${table == activeTable
                  ? '!bg-zinc-200 dark:!bg-zinc-600'
                  : 'bg-zinc-50 dark:bg-zinc-800'
                } w-full  hover:bg-zinc-100 dark:hover:!bg-zinc-700/80 border-zinc-200 dark:border-zinc-700 hover:cursor-pointer`}
              key={index}
            >
              <div
                className={`${table == activeTable ? 'visible' : 'hidden'} ${index == 0 ? 'rounded-tl' : index + 1 == filteredTables.length ? 'rounded-bl' : ''
                  }  h-[80px] mr-1.5 -ml-3 w-1.5 bg-zinc-500 dark:bg-zinc-400`}
              />

              {table?.source_type && (
                <div className="group shadow relative inline-flex items-center justify-center rounded-lg p-[2px] font-normal">
                  <div className="absolute h-full w-full rounded-md bg-gradient-to-tr from-[#5DA6FC]/50 via-[#FFC6BA]/50 to-[#ff5478]/50 dark:from-[#5DA6FC]/75 dark:via-[#FFC6BA]/75 dark:to-[#ff5478]/75"></div>
                  <div
                    className={`${table?.source_type === 'Connection'
                      ? 'bg-zinc-50 dark:bg-zinc-50'
                      : 'bg-zinc-50 dark:bg-zinc-900'
                      } duration-400 relative rounded p-2  dark:text-zinc-50`}
                  >
                    {table?.source_type === 'Connection' ? (
                      <img
                        className=""
                        src={table?.source?.connector.logo}
                        alt={table?.source?.connector.name}
                        style={{ maxWidth: '16px' }}
                      />
                    ) : table?.source_type === 'Datamodel' ? (
                      <CubeIcon className="h-4 w-4" />
                    ) : (
                      <DatabaseIcon className="h-4 w-4" />
                    )}
                  </div>
                </div>
              )}
              <div className="ml-3 flex flex-col">
                <p className="text-sm font-medium text-zinc-700  dark:text-zinc-50">
                  {table?.table_name}
                </p>
                <div className="text-xs text-light text-zinc-500 dark:text-zinc-300">
                  {table?.source_type === 'Connection' ? (
                    <p>
                      From the{' '}
                      <span className="font-normal underline underline-offset-2">
                        {table?.source_name}
                      </span>{' '}
                      Connector
                    </p>
                  ) : table?.source_type === 'Datamodel' ? (
                    <p>
                      From the{' '}
                      <span className="font-medium underline underline-offset-2 ">
                        {table?.source_name}
                      </span>{' '}
                      model
                    </p>
                  ) : (
                    <p>
                      Found in the{' '}
                      <span className="font-medium underline underline-offset-2 ">
                        {table?.database}
                      </span>{' '}
                      Database
                    </p>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="bg-zinc-50 dark:bg-zinc-800 w-7/12 pb-5 px-5 overflow-scroll ">
          {!activeTable && (
            <div className="justify-center items-center flex h-full flex-col">
              <TableIcon className="h-36 w-36 text-zinc-300 dark:text-zinc-400 stroke-[0.12px] -mt-6" />
              <p className="text-zinc-700 dark:text-zinc-50 text-base -mt-4">
                Catalogue: {filteredTables.length} tables
              </p>
              <p className="text-zinc-300 dark:text-zinc-400 text-sm font-light mt-1">
                No tables selected
              </p>
            </div>
          )}
          {activeTable && (
            <div className="">
              <div className="border-b pt-5 -mx-5 bg-zinc-50 dark:bg-zinc-800 sticky top-0 border-zinc-100 dark:border-zinc-700 flex flex-row items-start justify-between">
                <div className="px-5">
                  <h3 className="text-base font-semibold leading-7 text-zinc-900 dark:text-zinc-50">
                    {activeTable?.table_name}
                  </h3>
                  <p className="max-w-2xl mb-3 font-light text-sm leading-6 text-zinc-500 dark:text-zinc-200">
                    {activeTable?.source?.user?.name
                      ? 'Owner: ' + activeTable?.source?.user?.name
                      : 'No owner as the table is not currently maintained through Less'}
                  </p>
                </div>
              </div>
              <div className="mt-3 flex gap-1.5">
                <OnlyIconBtn
                  onClick={() => previewTableHandler()}
                  icon={<EyeIcon className="h-5 w-5" />}
                />
                <OnlyIconBtn
                  onClick={() => metadataTableHandler()}
                  icon={<DocumentReportIcon className="h-5 w-5" />}
                />
                {/* <OnlyIconBtn
                  onClick={(object) => console.log("Download file: ", object)}
                  icon={<DownloadIcon className="h-5 w-5" />}
                /> */}

                <OnlyIconBtn
                  classes="hover:!text-red-500 dark:hover:!bg-zinc-700 dark:hover:!text-red-300 hover:!bg-red-100"
                  icon={<TrashIcon className="h-5 w-5 " />}
                  onClick={(object) => {
                    deleteTable(object);
                  }}
                />
              </div>
              <div className="mt-3 border-y border-zinc-100 dark:border-zinc-700 overflow-hidden">
                <dl className="">
                  <div className="grid grid-cols-2 divide-y  divide-zinc-100 dark:divide-zinc-600">
                    <DataCatalogueRow header="Database" content={activeTable?.database} />
                    <DataCatalogueRow
                      header="Last updated"
                      content={
                        activeTable?.source_type != 'Database'
                          ? activeTable?.last_update
                          : 'Database assets are not updated through Less'
                      }
                    />
                    <DataCatalogueRow header="Asset type" content={activeTable?.source_type} />
                    <DataCatalogueRow header="Source" content={activeTable?.source_name} />
                    <DataCatalogueRow
                      header="Rows"
                      content={addThousandSeparators(activeTable?.rows)}
                    />
                    <DataCatalogueRow header="Columns" content={activeTable?.columns} />
                  </div>
                </dl>
                {/* Comment to add description */}
                {/* <div className="border-t border-zinc-100 !bg-transparent dark:border-zinc-700 py-4 sm:grid sm:grid-cols-2  sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-zinc-700 dark:text-zinc-50">Description</dt>
                  <div
                    contentEditable
                    placeholder='Testing'
                    ref={inputSin}
                    onKeyDown={keyPressEvent}
                    onInput={(e) => console.log('Text inside div', e.currentTarget.textContent)}
                    className={`mt-0.5 mx-1 px-1 py-2 ${!activeTable?.source?.description ? "mx-0 px-0 text-zinc-400/70 dark:text-zinc-400 italic font-light" : ""} placeholder:text-red-500 focus:ring-[#5DA6FC]  focus:outline-[#5DA6FC] dark:focus:ring-[#5DA6FC]  dark:focus:outline-[#5DA6FC] dark:border-0 text-sm leading-6 text-zinc-500 dark:text-zinc-200 sm:col-span-2 sm:mt-0`}
                  >
                    {activeTable?.source?.description || placeholder}
                  </div>
                </div> */}
              </div>
            </div>
          )}
        </div>
      </div>
      <div>
        {openPreview && (
          <BasicModal
            classes="w-[90vw] p-8"
            open
            onClose={() => {
              setPreviewModal(false), setpreviewData([]);
            }}
            content={
              <div>
                <ModalHeader
                  header="Table preview"
                  content={'View the first 20 rows of the dataset'}
                />
                <div className="pt-5 !overflow-scroll ">
                  <DataTable
                    classes="!max-h-[85vh] "
                    sidebar={false}
                    input={{ data: previewData }}
                  />
                  <button
                    type="button"
                    className="p-1 absolute top-[2px] right-[2px] rounded-md bg-none text-zinc-400 hover:text-zinc-500 dark:text-zinc-300 dark:hover:text-zinc-200  focus:outline-none focus:ring-0 transition duration-100"
                    onClick={() => {
                      setPreviewModal(false), setpreviewData([]);
                    }}
                  >
                    <XIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            }
          />
        )}
        {openMetadata && (
          <BasicModal
            classes="w-[600px] p-8"
            open
            onClose={() => {
              setMetadataModal(false), setMetadataData([]);
            }}
            content={
              <div>
                <ModalHeader
                  header={`Metadata for: ${metadataData[0]?.table_name}`}
                  content={'View the columns and data formats of the table'}
                />
                <div className="pt-5 ">
                  <DataTable
                    classes="!max-h-[65vh] w-[536px] -mt-2"
                    sidebar={false}
                    input={{ data: filteredMetadata }}
                  />
                  <button
                    type="button"
                    className="p-1 absolute top-[2px] right-[2px] rounded-md bg-none text-zinc-400 hover:text-zinc-500 dark:text-zinc-300 dark:hover:text-zinc-200  focus:outline-none focus:ring-0 transition duration-100"
                    onClick={() => {
                      setMetadataModal(false), setMetadataData([]);
                    }}
                  >
                    <XIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            }
          />
        )}

        {openDelete && (
          <DeleteModal
            open
            header="Delete Table"
            onClose={() => setDeleteModal(false)}
            description="Are you sure you want to delete this Connector? The Table will be permanently removed. This action cannot be undone."
            deletetxt="Delete Table"
            loader={true}
            deletepress={() => deleteTableHandler(activeTable)}
          />
        )}
      </div>
    </div>
  );
}

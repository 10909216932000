import {
  addNotification
} from 'redux/reducers/notification';

/**
 * Logs all actions and states after they are dispatched.
 */
const notification = ( /* store */ ) => (next) => (action) => {

  const notifySuccess = ({
    header,
    subheader
  }) => {
    next(
      addNotification({
        header,
        subheader,
        icon: 'success',
        timeout: 10000,
      })
    );
  };

  const notifyError = ({
    header,
    subheader
  }) => {
    next(
      addNotification({
        header,
        subheader,
        icon: 'error',
        timeout: 10000,
      })
    );
  };

  //Check for modelling actions specifically
  if (action.type == 'modelling/action/fulfilled' && action.payload.status == 'Error') {
    notifyError({
      header: 'Error',
      subheader: `${action.payload.error_message}`,
    });
    return next(action);
  }

  //Check for general rejections with a message from backend
  if (action?.meta?.requestStatus === 'rejected' && action.meta.rejectedWithValue) {

    let message = action.payload.code.data.message;

    // if (action.payload.code?.data?.errors) {
    //   action.payload.code?.data?.errors.forEach(errorMessage => {
    //     message += '\n' + errorMessage;        
    //   });
    // }

    notifyError({
      header: 'Error',
      subheader: `${message}`,
    });
    return next(action);
  }

  const successList = {
    'whitelist/update/fulfilled': {
      header: 'Success',
      subheader: 'We have updated your whitelist rules',
    },
    'profile/update/settings/fulfilled': {
      header: 'Success',
      subheader: 'We have updated your settings',
    },
    'database/delete/fulfilled': {
      header: 'Success',
      subheader: 'We have deleted your database.'
    },
    'connections/delete/fulfilled': {
      header: 'Success',
      subheader: 'We have deleted your connection.',
    },
    'connections/update/fulfilled': {
      header: 'Success',
      subheader: 'We have updated your connector.',
    },
    'connections/add/fulfilled': {
      header: 'HURRAY',
      subheader: 'You just added a new connector'
    },
    'model/delete/fulfilled': {
      header: 'Success',
      subheader: 'We have deleted your model.'
    },
    'models/update/fulfilled': {
      header: 'Success',
      subheader: 'Your model has succesfully updated',
    },
    'models/version/fulfilled': {
      header: 'Success',
      subheader: 'We saved a new version of your model',
    },
    'models/create/fulfilled': {
      header: 'Success',
      subheader: 'Congrats - you just created a new model',
    },
    'acl/asset/delete/fulfilled': {
      header: 'Success',
      subheader: 'The asset is now private and cannot be seen by the team',
    },
    'acl/asset/add/fulfilled': {
      header: 'Success',
      subheader: 'The asset is now shared with your team',
    },
    'modelling/ClearCache/fulfilled': {
      header: 'Success',
      subheader: 'Your cache has been cleared. The model will be re-run',
    },
    'connections/task/stop/fulfilled': {
      header: 'Success',
      subheader: 'Task successfully stopped',
    },
  };
  const errorList = {
    'connections/fetch/rejected': {
      header: 'Ah! Snap',
      subheader: "Couldn't load connections"
    },
    'profile/update/settings/rejected': {
      header: 'Error',
      subheader: 'We could not update your settings - contact support',
    },
    'whitelist/update/rejected': {
      header: 'Error',
      subheader: 'We could not save your rules. Make sure it is a valid IP',
    },
    'database/delete/rejected': {
      header: 'Error',
      subheader: 'We could not delete your database - please contact support',
    },
    'connections/delete/rejected': {
      header: 'Error',
      subheader: 'We could not delete your connection - please contact support',
    },
    'connections/update/rejected': {
      header: 'Error',
      subheader: 'We could not store your changes - reach out to us for help',
    },
    'connections/add/rejected': {
      header: 'Error',
      subheader: 'Something went wrong - we could not save your connection',
    },
    'model/delete/rejected': {
      header: 'Error',
      subheader: 'We could not delete your model - please contact support',
    },
    'models/update/rejected': {
      header: 'Error',
      subheader: 'We could not save your model - please try again and make sure to include a description',
    },
    'models/version/rejected': {
      header: 'Error',
      subheader: 'Your model could not be saved'
    },
    'models/autosave/rejected': {
      header: 'Error',
      subheader: 'There was an error while trying to autosave your model. We make checkpoint every 5 minutes to make sure to keep your work safe.'
    },
    'models/create/rejected': {
      header: 'Error',
      subheader: 'We failed to save your model - leave the window open and contact support'
    },
    'acl/asset/delete/rejected': {
      header: 'Error',
      subheader: 'You do not have access to perform this action - are you the owner?',
    },
    'acl/asset/add/rejected': {
      header: 'Error',
      subheader: 'You do not have access to perform this action',
    },
    'modelling/action/rejected': {
      header: 'Error',
      subheader: 'Unknown error - please contact support',
    },
    'modelling/ClearCache/rejected': {
      header: 'Error',
      subheader: 'We could not clear your cache - please contact support',
    },
    'connections/task/stop/rejected': {
      header: 'Error',
      subheader: 'An error ocurried while stopping the task - please contact support',
    },
  };

  if (Object.keys(successList).includes(action.type)) {
    notifySuccess(successList[action.type]);
    let result = next(action);
    return result;
  }

  if (Object.keys(errorList).includes(action.type)) {
    notifyError(errorList[action.type]);
    let result = next(action);
    return result;
  }

  // if (action?.meta?.requestStatus === 'rejected') {
  //   notifyError({
  //     header: 'Error',
  //     subheader: `You are not authorized to perform this action.`,
  //   });
  // }
  
  // if (action?.meta?.requestStatus === 'fulfilled') {
  //   notifySuccess({
  //     header: 'Succes',
  //     subheader: `${'Your action was successful'}`,
  //   });
  // }

  let result = next(action);
  return result;
};

export default notification;
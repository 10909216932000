import React from 'react';
import PropTypes from 'prop-types';
import { DownloadIcon } from '@heroicons/react/solid';
// components
import Table from 'components/Tables/Table';
import TableRow from 'components/Tables/TableRow';
import OnlyIconBtn from 'components/Buttons/OnlyIconBtn';
import ModalHeader from 'components/Layout/ModalHeader';
import RadixTooltip from 'components/Tooltips/RadixTooltip';

const ExportGrid = ({ object, tables, downloadData }) => {
  const headers = [
    { headerName: 'Table Name', classes: 'pl-3' },
    { headerName: '', classes: '' },
  ];

  const tableBody = tables.map((table) => (
    <tr key={table.table_name}>
      <TableRow classes="pl-3 py-1" content={table.table_name} />

      <td>
        <div className="justify-end flex flex-row flex-1 mt-1 mr-2 items-center whitespace-nowrap">
          <RadixTooltip tooltipText="Download .csv file">
            <OnlyIconBtn
              onClick={() => {
                downloadData(object, object.type, table.table_name);
              }}
              icon={<DownloadIcon className="h-5 w-5" aria-hidden="true" />}
            />
          </RadixTooltip>
        </div>
      </td>
    </tr>
  ));

  return (
    <div className="p-8 ">
      <ModalHeader
        header="Download data"
        content={
          <p className="mt-2  max-w-4xl text-sm align-baseline text-gray-500">
            You can download raw data to work with on your own computer. Helpful if you are or have
            some Excel-warriors in your team!
          </p>
        }
      />
      <div className="mt-2 ">
        <Table {...{ headers, tableBody }} />
      </div>
    </div>
  );
};

ExportGrid.propTypes = {
  object: PropTypes.object,
  type: PropTypes.string,
  tables: PropTypes.array,
  downloadData: PropTypes.func,
};

ExportGrid.defaultProps = {
  tables: [],
  downloadData: () => null,
};

export default ExportGrid;

import React from 'react';
import { VscJson } from 'react-icons/vsc';
import { TbArrowsJoin, TbLayersDifference } from 'react-icons/tb';
import { CgFormatUppercase } from 'react-icons/cg';

import { BsStopwatchFill } from 'react-icons/bs';
import { MdUpdate } from 'react-icons/md';

import {
  ArrowCircleDownIcon,
  ArrowCircleUpIcon,
  CalculatorIcon,
  CalendarIcon,
  ChartBarIcon,
  ChatIcon,
  CheckCircleIcon,
  ChevronDoubleRightIcon,
  FilterIcon,
  FingerPrintIcon,
  IdentificationIcon,
  PaperClipIcon,
  PencilIcon,
  ScaleIcon,
  SwitchVerticalIcon,
  ChatAltIcon,
  ExclamationIcon,
} from '@heroicons/react/solid';
import { FaRunning, FaFillDrip } from 'react-icons/fa';

export default [
  {
    name: 'input',
    text: 'Input',
    category: 'Basics',
    toolType: 'inputNode',
    tooltip: 'Import data',
    // vidLink: require('components/Animations/filter.json'),
    icon: <ArrowCircleDownIcon className="w-3.5 h-3.5" />,
  },
  {
    name: 'output',
    text: 'Output',
    category: 'Basics',
    toolType: 'outputNode',
    tooltip: 'Save results in new tables',
    defaultAnchor: 'dataframe',
    executeDisableDownstream: true,
    description: (configured, outputFile) =>
      configured === true && outputFile ? `Outputting ${outputFile}` : undefined,
    // vidLink: require('components/Animations/filter.json'),
    icon: <ArrowCircleUpIcon className="w-3.5 h-3.5" />,
  },
  {
    name: 'formula',
    text: 'Calculate',
    category: 'New Columns',
    toolType: 'defaultNode',
    tooltip: 'Create columns with math',
    defaultAnchor: 'dataframe',
    vidLink: require('components/Animations/calculate.json'),
    icon: <CalculatorIcon className="w-3.5 h-3.5" />,
    description: function (configured, formula, columnName) {
      return configured === true && formula ? `${columnName}: ${formula}` : undefined;
    },
  },
  {
    name: 'newtextcolumn',
    text: 'Text Column',
    category: 'New Columns',
    toolType: 'defaultNode',
    tooltip: 'Create columns with text',
    defaultAnchor: 'dataframe',
    vidLink: require('components/Animations/textColumn.json'),
    icon: <ChatIcon className="w-3.5 h-3.5" />,
    description: function (configured, column, formula) {
      return configured === true && column && formula ? `${column}=${formula}` : undefined;
    },
  },
  {
    name: 'filter',
    text: 'Filter',
    category: 'Basics',
    toolType: 'filterNode',
    tooltip: 'Remove observations by condition',
    defaultAnchor: 'dataframe',
    vidLink: require('components/Animations/filter.json'),
    icon: <FilterIcon className="w-3.5 h-3.5" />,
    description: function (configured, configuration) {
      const newArr = ['('];

      function printLoop() {
        configuration.conditions.forEach((element, index) => {
          if (element['label'] == 'OR') {
            newArr.push(')\nOR\n(');
          }
          newArr.push(`${element['column']} ${element['condition']} ${element['value']}`);
          if (
            configuration.conditions[index + 1] &&
            configuration.conditions[index + 1].label == 'AND'
          ) {
            newArr.push(' & ');
          }
        });
        newArr.push(')');
        return newArr.join('');
      }

      return configured === true && configuration.conditions.length > 0
        ? `${printLoop()}`
        : undefined;
    },
  },
  {
    name: 'format',
    text: 'Formats',
    category: 'Basics',
    toolType: 'defaultNode',
    tooltip: 'Change column format',
    defaultAnchor: 'dataframe',
    vidLink: require('components/Animations/formats.json'),
    icon: <FingerPrintIcon className="w-3.5 h-3.5" />,
    description: function (configured, columns) {
      const newArr = [];

      function printLoop() {
        let i = 0;
        for (let [key, value] of Object.entries(columns)) {
          i++;
          newArr.push(
            `${i}: ${key} => ${
              value === 'datetime64[ns]'
                ? 'Datetime'
                : value === 'string'
                ? 'Text'
                : value === 'float64'
                ? 'Decimal'
                : value === 'date'
                ? 'Date'
                : value === 'int64'
                ? 'Whole Number'
                : value === 'boolean'
                ? 'Boolean'
                : 'Unknown'
            }\n`
          );
        }
        return newArr;
      }

      return configured === true && columns ? printLoop() : undefined;
    },
    // description: function (configured, columns) {
    //   return configured === true && columns ? `${Object.entries(columns)}` : undefined;
    // },
  },
  {
    name: 'summarize',
    text: 'Group By',
    category: 'Reshape',
    toolType: 'defaultNode',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        className="w-3.5 h-3.5"
      >
        <path
          fillRule="evenodd"
          d="M1.5 7.125c0-1.036.84-1.875 1.875-1.875h6c1.036 0 1.875.84 1.875 1.875v3.75c0 1.036-.84 1.875-1.875 1.875h-6A1.875 1.875 0 011.5 10.875v-3.75zm12 1.5c0-1.036.84-1.875 1.875-1.875h5.25c1.035 0 1.875.84 1.875 1.875v8.25c0 1.035-.84 1.875-1.875 1.875h-5.25a1.875 1.875 0 01-1.875-1.875v-8.25zM3 16.125c0-1.036.84-1.875 1.875-1.875h5.25c1.036 0 1.875.84 1.875 1.875v2.25c0 1.035-.84 1.875-1.875 1.875h-5.25A1.875 1.875 0 013 18.375v-2.25z"
          clipRule="evenodd"
        />
      </svg>
    ),
    tooltip:
      'Hold one column constant and perform a calculation (e.g. min, max, sum) on another column',
    defaultAnchor: 'dataframe',
    vidLink: require('components/Animations/groupBy.json'),
    description: function (configured, groups, aggregations) {
      const newArr = [];
      const grouping = groups ? groups : {};

      function printLoop() {
        let i = 0;
        if (aggregations) {
          aggregations.forEach((element) => {
            i++;
            newArr.push(`${i}: ${element['column']}: ${element['aggregation']}\n`);
          });

          return newArr.join('');
        }
      }

      return configured === true && aggregations && groups
        ? `Group by: ${Object.keys(grouping)}\n${printLoop()}`
        : printLoop();
    },
  },
  {
    name: 'ifelse',
    text: 'IF Column',
    category: 'New Columns',
    toolType: 'defaultNode',
    icon: <ChevronDoubleRightIcon className="w-3.5 h-3.5" />,
    tooltip: 'Create columns with conditional statements',
    defaultAnchor: 'dataframe',
    vidLink: require('components/Animations/ifColumn.json'),
    description: function (configured, columnName, statements, ellers) {
      const newArr = [];

      function printLoop() {
        let i = 0;
        if (statements) {
          statements.forEach((element) => {
            i++;
            newArr.push(
              `${i}. ${i === 1 ? 'If' : 'Elseif'} ${element.expression} then ${element.thenValue}\n`
            );
          });

          return newArr.join('');
        }
      }
      return configured === true && columnName && statements && ellers
        ? `Column: ${columnName} =\n${printLoop()}Else: ${ellers}`
        : undefined;
    },
  },
  // {
  //   name: 'join',
  //   text: 'Combine',
  //   category: 'Merge',
  //   toolType: 'joinNode',
  //   icon: <TbArrowsJoin className="w-3.5 h-3.5" />,
  //   tooltip: 'Combine tables by common columns. Also known as join or merge.',
  //   defaultAnchor: 'df_l',
  //   vidLink: require('components/Animations/combine.json'),
  // },
  {
    name: 'join',
    text: 'Combine',
    category: 'Merge',
    toolType: 'joinNode',
    icon: <TbArrowsJoin className="w-3.5 h-3.5" />,
    tooltip: 'Combine tables by common columns. Also known as join or merge.',
    defaultAnchor: 'df_l',
    vidLink: require('components/Animations/combine.json'),
  },
  {
    name: 'select',
    text: 'Pick',
    category: 'Basics',
    toolType: 'defaultNode',
    tooltip: 'Remove unnecessary columns',
    defaultAnchor: 'dataframe',
    icon: <CheckCircleIcon className="w-3.5 h-3.5" />,
    vidLink: require('components/Animations/pickColumns.json'),
    description: function (configured, column) {
      const newArr = [];
      let t = 0;

      function printLoop() {
        for (let key of Object.keys(column)) {
          t = t + 1;
          if (t < 6) {
            newArr.push(`${key}\n`);
          } else if (t == 6) {
            newArr.push(`+ ${Object.keys(column).length} more`);
          } else {
            break;
          }
        }

        return newArr;
      }

      return configured && column ? printLoop() : undefined;
    },
  },
  {
    name: 'rename',
    text: 'Rename',
    category: 'Basics',
    toolType: 'defaultNode',
    tooltip: 'Rename columns',
    defaultAnchor: 'dataframe',
    icon: <PencilIcon className="w-3.5 h-3.5" />,
    vidLink: require('components/Animations/rename.json'),
    description: function (configured, column) {
      const newArr = [];

      function printLoop() {
        let i = 0;
        for (let [key, value] of Object.entries(column)) {
          i++;
          newArr.push(`${i}: ${key} => ${value}\n`);
        }
        return newArr;
      }

      return configured === true && column ? printLoop() : undefined;
    },
  },
  {
    name: 'union',
    text: 'Stack',
    category: 'Merge',
    toolType: 'unionNode',
    tooltip: 'Stack multiple tables on top of each other',
    defaultAnchor: 'df_1',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        className="w-3.5 h-3.5"
      >
        <path d="M16.5 6a3 3 0 00-3-3H6a3 3 0 00-3 3v7.5a3 3 0 003 3v-6A4.5 4.5 0 0110.5 6h6z" />
        <path d="M18 7.5a3 3 0 013 3V18a3 3 0 01-3 3h-7.5a3 3 0 01-3-3v-7.5a3 3 0 013-3H18z" />
      </svg>
    ),
    vidLink: require('components/Animations/union.json'),
  },
  {
    name: 'diff',
    text: 'Difference',
    category: 'New Columns',
    toolType: 'defaultNode',
    tooltip: 'Calculate the difference between your rows (helpful for time series data)',
    defaultAnchor: 'dataframe',
    icon: <TbLayersDifference className="w-3.5 h-3.5" />,
    vidLink: require('components/Animations/difference.json'),
    description: function (configured, column) {
      return configured === true && column ? `Difference: ${Object.keys(column)}` : undefined;
    },
  },
  {
    name: 'unique',
    text: 'Unique',
    category: 'Basics',
    toolType: 'defaultNode',
    tooltip: 'Remove duplicates',
    defaultAnchor: 'dataframe',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        className="w-3.5 h-3.5"
      >
        <path d="M6 3a3 3 0 00-3 3v2.25a3 3 0 003 3h2.25a3 3 0 003-3V6a3 3 0 00-3-3H6zM15.75 3a3 3 0 00-3 3v2.25a3 3 0 003 3H18a3 3 0 003-3V6a3 3 0 00-3-3h-2.25zM6 12.75a3 3 0 00-3 3V18a3 3 0 003 3h2.25a3 3 0 003-3v-2.25a3 3 0 00-3-3H6zM17.625 13.5a.75.75 0 00-1.5 0v2.625H13.5a.75.75 0 000 1.5h2.625v2.625a.75.75 0 001.5 0v-2.625h2.625a.75.75 0 000-1.5h-2.625V13.5z" />
      </svg>
    ),
    vidLink: require('components/Animations/unique.json'),
    description: function (configured, column) {
      return configured === true && column ? `Unique: ${Object.keys(column)}` : undefined;
    },
  },
  {
    name: 'sort',
    text: 'Sort',
    category: 'Basics',
    toolType: 'defaultNode',
    tooltip: 'Sort your data based on a given column',
    defaultAnchor: 'dataframe',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        className="w-3.5 h-3.5"
      >
        <path
          fillRule="evenodd"
          d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm8.25 5.25a.75.75 0 01.75-.75h8.25a.75.75 0 010 1.5H12a.75.75 0 01-.75-.75z"
          clipRule="evenodd"
        />
      </svg>
    ),
    vidLink: require('components/Animations/sort.json'),
    description: function (configured, column) {
      const newArr = [];

      function printLoop() {
        let i = 0;
        for (let [key, value] of Object.entries(column)) {
          i++;
          newArr.push(`${i}: ${key}=${value === true ? 'ascending\n' : 'descending\n'}`);
        }
        return newArr;
      }

      return configured === true && column ? printLoop() : undefined;
    },
  },
  {
    name: 'dateFormat',
    text: 'Date Format',
    category: 'New Columns',
    toolType: 'defaultNode',
    tooltip: 'Format your date into weekdays, week numbers, quarters, etc.',
    defaultAnchor: 'dataframe',
    icon: <CalendarIcon className="w-3.5 h-3.5" />,
    vidLink: require('components/Animations/dateFormat.json'),
    description: function (configured, column, output) {
      return configured === true && column && output
        ? `${column} to ${Object.keys(output)}`
        : undefined;
    },
  },
  {
    name: 'parseJson',
    text: 'Parse JSON',
    category: 'New Columns',
    toolType: 'defaultNode',
    tooltip: 'Parse nested JSON into new columns',
    defaultAnchor: 'dataframe',
    icon: <VscJson className="w-3.5 h-3.5" />,
    vidLink: require('components/Animations/parseJSON.json'),
  },
  {
    name: 'transpose',
    text: 'Transpose',
    category: 'Reshape',
    toolType: 'defaultNode',
    tooltip: 'Transform columns to rows',
    defaultAnchor: 'dataframe',
    icon: (
      <svg
        viewBox="0 0 24 24"
        fill="currentColor"
        className="w-3.5 h-3.5"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M2.53247 0C1.13383 0 0 1.13383 0 2.53247V5.14936C0 5.61558 0.377942 5.99352 0.844157 5.99352H5.14936C5.61558 5.99352 5.99352 5.61558 5.99352 5.14936V0.844157C5.99352 0.377942 5.61558 0 5.14936 0H2.53247Z" />
        <path d="M0.844157 7.25975C0.377942 7.25975 0 7.6377 0 8.10391V22.4546C0 22.9208 0.377942 23.2987 0.844157 23.2987H5.14936C5.61558 23.2987 5.99352 22.9208 5.99352 22.4546V8.10391C5.99352 7.6377 5.61558 7.25975 5.14936 7.25975H0.844157Z" />
        <path d="M8.10391 5.99352C7.6377 5.99352 7.25975 5.61558 7.25975 5.14936V0.844158C7.25975 0.377943 7.6377 1.07172e-06 8.10391 1.07172e-06H22.4546C22.9208 1.07172e-06 23.2987 0.377943 23.2987 0.844158V5.14936C23.2987 5.61558 22.9208 5.99352 22.4546 5.99352H8.10391Z" />
        <path d="M7.25975 18.9935L15.6233 24L15.69 19.7777C16.1397 19.6528 16.5845 19.4979 17.0133 19.3064C17.7982 18.9559 18.5294 18.4828 19.1386 17.8472C20.9052 16.0044 21.606 13.4727 21.8839 11.4973C22.0247 10.4964 22.0613 9.60609 22.0629 8.96515C22.0638 8.64406 22.0558 8.38393 22.0474 8.20187C22.0432 8.1108 22.039 8.03914 22.0356 7.98903C22.035 7.98005 22.0344 7.97177 22.0339 7.96419C22.033 7.95059 22.0321 7.93925 22.0314 7.93023L22.0308 7.92261L22.0301 7.91342L22.0297 7.90824L22.0295 7.9058C22.0294 7.90552 22.0294 7.90526 21.1883 7.97729L22.0294 7.90526C21.9896 7.44074 21.5808 7.09643 21.1163 7.13621C20.6519 7.17598 20.3077 7.58457 20.3472 8.04891L20.3473 8.04932L20.3473 8.04985C20.3474 8.05145 20.3477 8.05446 20.348 8.05884C20.3487 8.06762 20.3497 8.0819 20.351 8.10143C20.3536 8.1405 20.3573 8.2005 20.3609 8.27934C20.3681 8.43712 20.3754 8.66985 20.3746 8.96086C20.3732 9.54414 20.3396 10.355 20.212 11.2621C19.9531 13.1026 19.3243 15.2138 17.9199 16.6788C17.8615 16.7397 17.8011 16.7989 17.7389 16.8564C17.1945 17.3591 16.5043 17.7336 15.718 18.0028L15.7772 14.2537L7.25975 18.9935Z" />
      </svg>
    ),
    vidLink: require('components/Animations/transpose.json'),
    description: function (configured, keyfields, rows) {
      return configured == true && keyfields && rows
        ? `Constant: ${Object.keys(keyfields)}\nTo rows: ${Object.keys(rows)}`
        : undefined;
    },
  },
  {
    name: 'pivot',
    text: 'Pivot',
    category: 'Reshape',
    toolType: 'defaultNode',
    tooltip: 'Transform rows to columns',
    defaultAnchor: 'dataframe',
    icon: (
      <svg
        viewBox="0 0 24 24"
        fill="currentColor"
        className="w-3.5 h-3.5"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M2.6087 0C1.16795 0 0 1.16795 0 2.6087V5.30435C0 5.7846 0.389318 6.17391 0.869565 6.17391H5.30435C5.7846 6.17391 6.17391 5.7846 6.17391 5.30435V0.869565C6.17391 0.389318 5.7846 0 5.30435 0H2.6087Z" />
        <path d="M0.869565 7.47826C0.389318 7.47826 0 7.86758 0 8.34783V23.1304C0 23.6107 0.389318 24 0.869565 24H5.30435C5.7846 24 6.17391 23.6107 6.17391 23.1304V8.34783C6.17391 7.86758 5.7846 7.47826 5.30435 7.47826H0.869565Z" />
        <path d="M8.34783 6.17391C7.86758 6.17391 7.47826 5.7846 7.47826 5.30435V0.869566C7.47826 0.389319 7.86758 1.10398e-06 8.34783 1.10398e-06H23.1304C23.6107 1.10398e-06 24 0.389319 24 0.869566V5.30435C24 5.7846 23.6107 6.17391 23.1304 6.17391H8.34783Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.132 7.43505L15.0595 16.1004L8.4595 20.3116C7.98371 20.2499 7.5478 20.5854 7.4856 21.0612C7.42335 21.5374 7.75892 21.9739 8.23512 22.0362L8.34783 21.1739C8.23512 22.0362 8.2354 22.0362 8.2357 22.0362L8.23641 22.0363L8.23822 22.0366L8.24339 22.0372C8.24752 22.0377 8.25302 22.0384 8.25985 22.0392C8.27351 22.0409 8.29252 22.0431 8.31665 22.0458C8.3649 22.0511 8.43369 22.0582 8.52119 22.0659C8.69612 22.0814 8.9463 22.0994 9.25719 22.1112C9.87798 22.1346 10.7462 22.1329 11.744 22.0329C13.724 21.8343 16.2965 21.2404 18.4405 19.6043C19.6014 18.7185 20.3101 17.4575 20.738 16.1345L25.1002 16.1607L20.132 7.43505ZM15.0595 16.1004L8.45963 20.3116L8.46047 20.3117L8.46839 20.3127C8.4763 20.3136 8.48942 20.3152 8.50754 20.3172C8.54377 20.3212 8.59992 20.327 8.67422 20.3335C8.82289 20.3467 9.04377 20.3627 9.32271 20.3733C9.88156 20.3943 10.6681 20.3929 11.5705 20.3024C13.391 20.1199 15.6011 19.5834 17.3855 18.2217C18.05 17.7147 18.5419 16.9918 18.8934 16.1234L15.0595 16.1004Z"
        />
      </svg>
    ),
    vidLink: require('components/Animations/pivot.json'),
    description: function (configured, id, headers, values, aggregation) {
      return configured == true && id && headers && values && aggregation
        ? `ID: ${Object.keys(id)}\nHeaders: ${headers}\nValues: ${values}\nAgg: ${aggregation}`
        : undefined;
    },
  },
  {
    name: 'datediff',
    text: 'Date Difference',
    category: 'New Columns',
    toolType: 'defaultNode',
    tooltip: 'Get the difference between dates in seconds, minutes or hours',
    defaultAnchor: 'dataframe',
    icon: <BsStopwatchFill className="w-3.5 h-3.5" />,
    vidLink: require('components/Animations/datediff.json'),
    description: function (configured, column1, column2, unit) {
      return configured === true && column1 && column2 && unit
        ? `${column1}\n-\n${column2}\n(${unit})`
        : undefined;
    },
  },
  {
    name: 'datemath',
    text: 'Date Math',
    category: 'New Columns',
    toolType: 'defaultNode',
    tooltip: 'Add or subtract values from dates',
    defaultAnchor: 'dataframe',
    icon: (
      <svg
        viewBox="0 0 24 24"
        fill="currentColor"
        className="w-3.5 h-3.5"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.23409 1.28476C4.41828 1.10243 4.66809 1 4.92857 1C5.18905 1 5.43886 1.10243 5.62305 1.28476C5.80724 1.46708 5.91071 1.71437 5.91071 1.97222V2.94444H11.8036V1.97222C11.8036 1.71437 11.907 1.46708 12.0912 1.28476C12.2754 1.10243 12.5252 1 12.7857 1C13.0462 1 13.296 1.10243 13.4802 1.28476C13.6644 1.46708 13.7679 1.71437 13.7679 1.97222V2.94444H14.75C15.271 2.94444 15.7706 3.1493 16.139 3.51396C16.5073 3.87861 16.7143 4.37319 16.7143 4.88889V8.77778C12.8088 8.77778 9.64286 11.9118 9.64286 15.7778C9.64286 16.0407 9.6575 16.3002 9.68602 16.5556H2.96429C2.44332 16.5556 1.9437 16.3507 1.57533 15.986C1.20695 15.6214 1 15.1268 1 14.6111V4.88889C1 4.37319 1.20695 3.87861 1.57533 3.51396C1.9437 3.1493 2.44332 2.94444 2.96429 2.94444H3.94643V1.97222C3.94643 1.71437 4.0499 1.46708 4.23409 1.28476ZM4.23409 6.14587C4.41828 5.96354 4.66809 5.86111 4.92857 5.86111H12.7857C13.0462 5.86111 13.296 5.96354 13.4802 6.14587C13.6644 6.3282 13.7679 6.57548 13.7679 6.83333C13.7679 7.09118 13.6644 7.33847 13.4802 7.5208C13.296 7.70312 13.0462 7.80556 12.7857 7.80556H4.92857C4.66809 7.80556 4.41828 7.70312 4.23409 7.5208C4.0499 7.33847 3.94643 7.09118 3.94643 6.83333C3.94643 6.57548 4.0499 6.3282 4.23409 6.14587Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.7143 22C18.3814 22 19.9802 21.3444 21.159 20.1776C22.3378 19.0107 23 17.428 23 15.7778C23 14.1275 22.3378 12.5449 21.159 11.378C19.9802 10.2111 18.3814 9.55556 16.7143 9.55556C15.0472 9.55556 13.4484 10.2111 12.2696 11.378C11.0908 12.5449 10.4286 14.1275 10.4286 15.7778C10.4286 17.428 11.0908 19.0107 12.2696 20.1776C13.4484 21.3444 15.0472 22 16.7143 22ZM17.5 13.4444C17.5 13.2382 17.4172 13.0403 17.2699 12.8945C17.1225 12.7486 16.9227 12.6667 16.7143 12.6667C16.5059 12.6667 16.3061 12.7486 16.1587 12.8945C16.0114 13.0403 15.9286 13.2382 15.9286 13.4444V15H14.3571C14.1488 15 13.9489 15.0819 13.8016 15.2278C13.6542 15.3737 13.5714 15.5715 13.5714 15.7778C13.5714 15.9841 13.6542 16.1819 13.8016 16.3278C13.9489 16.4736 14.1488 16.5556 14.3571 16.5556H15.9286V18.1111C15.9286 18.3174 16.0114 18.5152 16.1587 18.6611C16.3061 18.8069 16.5059 18.8889 16.7143 18.8889C16.9227 18.8889 17.1225 18.8069 17.2699 18.6611C17.4172 18.5152 17.5 18.3174 17.5 18.1111V16.5556H19.0714C19.2798 16.5556 19.4797 16.4736 19.627 16.3278C19.7744 16.1819 19.8571 15.9841 19.8571 15.7778C19.8571 15.5715 19.7744 15.3737 19.627 15.2278C19.4797 15.0819 19.2798 15 19.0714 15H17.5V13.4444Z"
        />
      </svg>
    ),
    description: function (configured, target, newColumn, units, unit) {
      return configured === true && target && newColumn && units && unit
        ? `${newColumn} =\n${units.slice(0, 1) === '-' ? 'Subtract' : 'Add'} ${units.replace(
            /\D/g,
            ''
          )} ${unit}\n${units.slice(0, 1) === '-' ? 'from' : 'to'} ${target}`
        : undefined;
    },
    vidLink: require('components/Animations/datemath.json'),
  },
  {
    name: 'compare',
    text: 'Compare',
    category: 'New Columns',
    toolType: 'defaultNode',
    tooltip: 'Compare the value of a current row to a previous or past row',
    defaultAnchor: 'dataframe',

    icon: <ScaleIcon className="w-3.5 h-3.5" />,
    vidLink: require('components/Animations/compare.json'),
    description: function (configured, column, value) {
      return configured === true && column && value
        ? `${Object.keys(column)}: ${value > 0 ? '-' : '+'}${value} rows`
        : undefined;
    },
  },
  {
    name: 'append',
    text: 'Append',
    category: 'Merge',
    toolType: 'unionNode',
    tooltip: 'Add columns from one table to another',
    defaultAnchor: 'df_1',
    icon: <PaperClipIcon className="w-3.5 h-3.5" />,
    vidLink: require('components/Animations/append.json'),
  },
  {
    name: 'limit',
    text: 'Limit',
    category: 'Basics',
    toolType: 'defaultNode',
    tooltip: 'Limit the number of rows in your dataset',
    defaultAnchor: 'dataframe',
    icon: (
      <svg
        viewBox="0 0 24 24"
        fill="currentColor"
        className="w-3.5 h-3.5"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.1429 21.999C12.1904 21.9997 12.238 22 12.2857 22C17.8086 22 22.2857 17.5228 22.2857 12C22.2857 6.47715 17.8086 2 12.2857 2C12.238 2 12.1904 2.00033 12.1429 2.001C12.0953 2.00033 12.0477 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C12.0477 22 12.0953 21.9997 12.1429 21.999ZM9.42857 20.179V3.82097C5.95105 4.9132 3.42857 8.16202 3.42857 12C3.42857 15.838 5.95105 19.0868 9.42857 20.179Z"
        />
      </svg>
    ),
    vidLink: require('components/Animations/limit.json'),
    description: function (configured, rowlimit) {
      return configured === true && rowlimit ? `Limited to ${rowlimit} rows` : undefined;
    },
  },
  {
    name: 'splitColumns',
    text: 'Split Columns',
    category: 'New Columns',
    toolType: 'defaultNode',
    tooltip: 'Split a column to rows or columns',
    defaultAnchor: 'dataframe',
    icon: (
      <svg
        viewBox="0 0 24 24"
        fill="currentColor"
        className="w-3.5 h-3.5"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M9.97058 6.00004H3.07253C2.48367 6.00004 2 6.48371 2 7.07257V16.936C2 17.5248 2.48367 18.0085 3.07253 18.0085H9.99161C10.5805 18.0085 11.0641 17.5248 11.0641 16.936L11.0643 12.8139H8.33026V14.202C8.33026 14.5174 7.97276 14.6857 7.72045 14.5174L4.60795 12.3091C4.39758 12.1619 4.39758 11.8465 4.60795 11.6782L7.72045 9.47008C7.97277 9.28084 8.33026 9.47008 8.33026 9.78548V11.1735H11.0431L11.0433 7.05153C11.0433 6.48382 10.5596 6 9.97065 6L9.97058 6.00004Z" />
        <path d="M20.9063 6H14.0082C13.4194 6 12.9357 6.48367 12.9357 7.07253V11.1735H15.6486V9.78544C15.6486 9.47004 16.0061 9.30178 16.2584 9.47004L19.3709 11.6783C19.5813 11.8256 19.5813 12.141 19.3709 12.3093L16.2584 14.5175C16.0061 14.7068 15.6486 14.5175 15.6486 14.2021V12.8141H12.9355V16.915C12.9355 17.5039 13.4192 17.9876 14.0081 17.9876H20.9272C21.516 17.9876 21.9997 17.5039 21.9997 16.915L21.9998 7.05165C21.9789 6.48394 21.4952 6.00012 20.9064 6.00012L20.9063 6Z" />
      </svg>
    ),
    vidLink: require('components/Animations/split.json'),
    description: function (configured, target, direction, delimiter) {
      return configured === true && target && direction && delimiter
        ? `Split ${target}\nto ${direction}\nby ${delimiter}`
        : undefined;
    },
  },
  {
    name: 'texttransform',
    text: 'Transform',
    category: 'Basics',
    toolType: 'defaultNode',
    tooltip: 'Trim or make text lowercase, UPPERCASE and Titlecase',
    defaultAnchor: 'dataframe',
    icon: <CgFormatUppercase className="w-3.5 h-3.5" />,
    description: function (configured, columns, transform) {
      return configured === true && columns && transform
        ? `${transform.operation} ${
            transform?.value ? '(' + transform?.value + ')' : ''
          }: ${Object.keys(columns)}`
        : undefined;
    },
    vidLink: require('components/Animations/textransform.json'),
  },
  {
    name: 'rowid',
    text: 'Row ID',
    category: 'New Columns',
    toolType: 'defaultNode',
    tooltip: 'Add a row ID column',
    defaultAnchor: 'dataframe',
    icon: <IdentificationIcon className="w-3.5 h-3.5" />,
    vidLink: require('components/Animations/rowid.json'),
    description: function () {
      return 'Adding Row ID';
    },
  },
  {
    name: 'runninginterval',
    text: 'Running Interval',
    category: 'New Columns',
    toolType: 'defaultNode',
    tooltip: 'Aggregate intervals in past or future rows',
    defaultAnchor: 'dataframe',
    icon: <FaRunning className="w-3.5 h-3.5" />,
    vidLink: require('components/Animations/running.json'),
    description: function (configured, target, offset, period, agg) {
      return configured === true && target && offset && period && agg
        ? `From index row ${offset}\non the ${target} column,\n${agg} ${period} rows`
        : undefined;
    },
  },
  {
    name: 'filler',
    text: 'Fill',
    category: 'Basics',
    toolType: 'defaultNode',
    tooltip: 'Fill empty values with previous value',
    defaultAnchor: 'dataframe',
    icon: <FaFillDrip className="w-3.5 h-3.5" />,
    vidLink: require('components/Animations/fill.json'),
    description: function (configured, columns) {
      return configured === true && columns ? `Fill: ${Object.keys(columns)}` : undefined;
    },
  },
  {
    name: 'cumulCalc',
    text: 'Cumulative',
    category: 'New Columns',
    toolType: 'defaultNode',
    tooltip: 'Create cumulative calculations',
    defaultAnchor: 'dataframe',
    icon: <ChartBarIcon className="w-3.5 h-3.5" />,
    vidLink: require('components/Animations/cumulative.json'),
    description: function (configured, groups, aggregations) {
      const newArr = [];
      const grouping = groups ? groups : {};

      function printLoop() {
        let i = 0;
        if (aggregations) {
          aggregations.forEach((element) => {
            i++;
            newArr.push(`${i}: ${element['column']}: ${element['aggregation']}\n`);
          });

          return newArr.join('');
        }
      }

      return configured === true && aggregations && Object.keys(grouping).length
        ? `Group by: ${Object.keys(grouping)}\n${printLoop()}`
        : printLoop();
    },
  },
  {
    name: 'replace',
    text: 'Replace',
    category: 'Basics',
    toolType: 'defaultNode',
    tooltip: 'Replace text with other text',
    defaultAnchor: 'dataframe',
    icon: <SwitchVerticalIcon className="w-3.5 h-3.5" />,
    vidLink: require('components/Animations/replace.json'),
    description: function (configured, columns, value, replacement) {
      return configured === true && columns && value && replacement
        ? `Replace '${value}' with '${replacement}' in column(s): ${Object.keys(columns)}`
        : undefined;
    },
  },
  {
    name: 'createRows',
    text: 'Create Rows',
    category: 'Basics',
    toolType: 'defaultNode',
    tooltip: 'Generate new rows',
    defaultAnchor: 'dataframe',
    icon: (
      <svg className="w-3.5 h-3.5" fill="none" viewBox="0 0 24 24">
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M5.75 19.2502H18.25C18.8023 19.2502 19.25 18.8025 19.25 18.2502V5.75C19.25 5.19772 18.8023 4.75 18.25 4.75H5.75C5.19772 4.75 4.75 5.19772 4.75 5.75V18.2502C4.75 18.8025 5.19772 19.2502 5.75 19.2502Z"
        ></path>{' '}
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M19.25 9.25L5.25 9.25"
        ></path>{' '}
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M19.25 14.75L5.25 14.75"
        ></path>{' '}
      </svg>
    ),
    description: function (configured, splitNumber, splitColumns) {
      return configured === true && splitNumber && splitColumns
        ? `Create columns with: ${splitNumber}\nDisaggregate: ${Object.keys(splitColumns)}`
        : undefined;
    },
    vidLink: require('components/Animations/createRows.json'),
  },
  {
    name: 'api',
    text: 'API',
    category: 'Utilities',
    toolType: 'defaultNode',
    tooltip: 'Call third party APIs from the Canvas',
    defaultAnchor: 'dataframe',
    executeDisableDownstream: true,
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        className="w-3.5 h-3.5"
      >
        <path
          filleRule="evenodd"
          d="M15 3.75a.75.75 0 01.75-.75h4.5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0V5.56l-4.72 4.72a.75.75 0 11-1.06-1.06l4.72-4.72h-2.69a.75.75 0 01-.75-.75z"
          clipRule="evenodd"
        />
        <path
          filleRule="evenodd"
          d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
          clipRule="evenodd"
        />
      </svg>
    ),
  },
  {
    name: 'today',
    text: 'Today',
    category: 'Utilities',
    toolType: 'defaultNode',
    tooltip: 'Create a columns with the current datetime in a specific timezone',
    defaultAnchor: 'dataframe',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="w-3.5 h-3.5"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
        />
      </svg>
    ),
    description: function (configured, name, timezone) {
      return configured === true && name && timezone ? `${name} in ${timezone.value}` : undefined;
    },
    vidLink: require('components/Animations/today.json'),
  },
  {
    name: 'comment',
    text: 'Comment',
    category: 'Utilities',
    toolType: 'commentNode',
    tooltip: 'Add a comment',
    vidLink: require('components/Animations/comment.json'),
    icon: <ChatAltIcon className="w-3.5 h-3.5" />,
  },
  {
    name: 'holidays',
    text: 'Add Business Days',
    category: 'New Columns',
    toolType: 'joinNode',
    defaultAnchor: 'df_l',
    tooltip: 'Add days to date accounting for holidays',
    vidLink: require('components/Animations/holidays.json'),
    icon: (
      <svg
        className="w-3.5 h-3.5"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="1.5"
      >
        <g clipPath="url(#clip0_2310_36)">
          <rect width="24" height="24" fill="none" />
          <path
            d="M1 7.78261C1 6.24582 2.23508 5 3.75862 5H18.2414C19.765 5 21 6.24582 21 7.78261V18.2174C21 19.7542 19.765 21 18.2414 21H3.75862C2.23508 21 1 19.7542 1 18.2174V7.78261Z"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6 5V3.90909C6 2.30244 7.23983 1 8.76923 1H12.2308C13.7602 1 15 2.30244 15 3.90909V5"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1 12.5714H21"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5.71387 11V14.4286"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.8574 11V14.4286"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <circle
            cx="17.7144"
            cy="17.7143"
            r="6.03571"
            fill="#FBE9E4"
            stroke="currentColor"
            strokeWidth="1"
          />
          <path
            d="M16.0205 16.8542V15.3959L15.3955 15.6042M16.0205 16.8542H15.3955M16.0205 16.8542H16.4372M17.8955 16.2292H20.6038M17.8955 19.7709H20.6038M16.4372 20.6042H15.3955L16.3234 19.755C16.3756 19.7074 16.4122 19.645 16.4283 19.5762C16.4444 19.5074 16.4394 19.4353 16.4138 19.3694C16.3882 19.3035 16.3432 19.2469 16.2849 19.207C16.2265 19.1671 16.1574 19.1458 16.0868 19.1459H15.6038C15.5486 19.1459 15.4956 19.1678 15.4565 19.2069C15.4175 19.246 15.3955 19.299 15.3955 19.3542"
            stroke="currentColor"
            strokeWidth="0.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>{' '}
        <defs>
          <clipPath id="clip0_2310_36">
            <rect width="24" height="24" fill="white" />
          </clipPath>{' '}
        </defs>{' '}
      </svg>
    ),
  },

  {
    name: 'changeColumns',
    text: 'Change Columns',
    category: 'Basics',
    toolType: 'defaultNode',
    tooltip: 'Fix your columns',
    defaultAnchor: 'dataframe',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-3.5 h-3.5"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M9 4.5v15m6-15v15m-10.875 0h15.75c.621 0 1.125-.504 1.125-1.125V5.625c0-.621-.504-1.125-1.125-1.125H4.125C3.504 4.5 3 5.004 3 5.625v12.75c0 .621.504 1.125 1.125 1.125z"
        />
      </svg>
    ),
    vidLink: require('components/Animations/changeColumns.json'),
  },
  {
    name: 'stringtodate',
    text: 'String to Date',
    category: 'New Columns',
    toolType: 'defaultNode',
    tooltip: 'Change your text column to a date with a custom format',
    defaultAnchor: 'dataframe',
    vidLink: require('components/Animations/stringtodate.json'),
    icon: <MdUpdate className="w-3.5 h-3.5" />,
  },
  {
    name: 'regex',
    text: 'Regex',
    category: 'New Columns',
    toolType: 'defaultNode',
    tooltip: 'Advanced! Extract specific parts of text columns with regex',
    defaultAnchor: 'dataframe',
    // vidLink: require('components/Animations/filter.json'),
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon-tabler icon-tabler-regex"
        width="14"
        height="14"
        viewBox="0 0 24 24"
        strokeWidth="2"
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none">
          {' '}
        </path>{' '}
        <path d="M6.5 15a2.5 2.5 0 1 1 0 5a2.5 2.5 0 0 1 0 -5z"> </path>{' '}
        <path d="M17 7.875l3 -1.687"> </path> <path d="M17 7.875v3.375"> </path>{' '}
        <path d="M17 7.875l-3 -1.687"> </path> <path d="M17 7.875l3 1.688"> </path>{' '}
        <path d="M17 4.5v3.375"> </path> <path d="M17 7.875l-3 1.688"> </path>{' '}
      </svg>
    ),
  },
  {
    name: 'holidaysDiff',
    text: 'Count Business Days',
    category: 'New Columns',
    toolType: 'joinNode',
    defaultAnchor: 'df_l',
    tooltip: 'Count business days between two dates',
    vidLink: require('components/Animations/holidays.json'),
    icon: (
      <svg
        className="w-3.5 h-3.5"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="1.5"
      >
        <g clipPath="url(#clip0_2307_19)">
          <rect width="24" height="24" fill="none" />
          <path
            d="M1 7.78261C1 6.24582 2.23508 5 3.75862 5H18.2414C19.765 5 21 6.24582 21 7.78261V18.2174C21 19.7542 19.765 21 18.2414 21H3.75862C2.23508 21 1 19.7542 1 18.2174V7.78261Z"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6 5V3.90909C6 2.30244 7.23983 1 8.76923 1H12.2308C13.7602 1 15 2.30244 15 3.90909V5"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1 12.5714H21"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5.71387 11V14.4286"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.8574 11V14.4286"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <circle
            cx="17.7144"
            cy="17.7143"
            r="6.03571"
            fill="#FBE9E4"
            stroke="currentColor"
            strokeWidth="1"
          />
          <path
            d="M17.7002 14.3146V21.0854"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.8"
          />
          <path
            d="M21.0853 17.7H14.3145"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.8"
          />
        </g>{' '}
        <defs>
          <clipPath id="clip0_2307_19">
            <rect width="24" height="24" fill="white" />
          </clipPath>{' '}
        </defs>{' '}
      </svg>
    ),
  },
  {
    name: 'warningXRows',
    text: 'Row Warning',
    category: 'Utilities',
    toolType: 'outputNode',
    tooltip: 'Generates a warning if more than X rows',
    defaultAnchor: 'dataframe',
    icon: <ExclamationIcon className="w-3.5 h-3.5" />,
    description: (configured, rows) =>
      configured === true ? `Checking for more than ${rows} rows` : undefined,
  },
  {
    name: 'multiRowCalc',
    text: 'Multi Row',
    category: 'Basics',
    toolType: 'defaultNode',
    tooltip: 'Gives you the ability to do calculations accross multiple rows',
    defaultAnchor: 'dataframe',
    icon: (
      <svg
        className="w-3.5 h-3.5"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
      >
        <g id="insert-row">
          <path id="Rectangle 852" stroke="currentColor" d="m7 21 0-6 15 0v6L7 21Z"></path>
          <path id="Rectangle 853" stroke="currentColor" d="m7 9 0-6 15 0v6L7 9Z"></path>
          <path id="Vector 1864" stroke="currentColor" d="M2 15.5 5.5 12 2 8.5"></path>
        </g>
      </svg>
    ),
  },
  {
    name: 'mapbox',
    text: 'MapBox - GeoCoding',
    category: 'Utilities',
    toolType: 'defaultNode',
    tooltip: 'Gives you the ability to do geocoding with MapBox',
    defaultAnchor: 'dataframe',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="w-3.5 h-3.5 icon icon-tabler icon-tabler-world"
        viewBox="0 0 24 24"
        strokeWidth="2"
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none">
          {' '}
        </path>{' '}
        <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"> </path> <path d="M3.6 9h16.8"> </path>{' '}
        <path d="M3.6 15h16.8"> </path> <path d="M11.5 3a17 17 0 0 0 0 18"> </path>{' '}
        <path d="M12.5 3a17 17 0 0 1 0 18"> </path>{' '}
      </svg>
    ),
  },
  {
    name: 'pointinpoly',
    text: 'Point-in-Polygon',
    category: 'Utilities',
    toolType: 'defaultNode',
    tooltip: 'Check if a point is inside a polygon',
    defaultAnchor: 'dataframe',
    icon: (
      <svg
        className="h-3.5 w-3.5"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.895 16C11.895 16 17.79 11.579 17.79 7.637C17.79 4.579 15.579 2 11.895 2C8.21 2 6 4.579 6 7.637C6 11.579 11.895 16 11.895 16Z"
          stroke="currentColor"
          strokeWidth="1.5"
        />
        <path
          d="M10.4648 5.677H13.3248V8.537H10.4648V5.677Z"
          stroke="currentColor"
          strokeWidth="1.5"
        />
        <path
          d="M15.0098 20.8C13.9746 20.9027 12.935 20.9534 11.8948 20.952C6.73478 20.952 2.55078 19.755 2.55078 18.28C2.55078 17.315 4.33878 16.47 7.01978 16M17.9998 20.302C19.9828 19.812 21.2378 19.088 21.2378 18.28C21.2378 17.34 19.5398 16.513 16.9738 16.037"
          stroke="currentColor"
          strokeWidth="1.5"
        />
        <path
          d="M12.6699 18.157L15.2059 20.695L12.6689 23.232"
          stroke="currentColor"
          strokeWidth="1.5"
        />
      </svg>
    ),
  },
  // {
  //   name: 'toTableau',
  //   text: 'Output to Tableau',
  //   category: 'Utilities',
  //   toolType: 'outputNode',
  //   tooltip: 'Publish your table as a Hyper file to a Tableau Server',
  //   defaultAnchor: 'dataframe',
  //   executeDisableDownstream: true,
  //   description: (configured, outputFile) =>
  //     configured === true && outputFile ? `Outputting ${outputFile}` : undefined,
  //   icon: (
  //     <svg className="h-3.5 w-3.5" viewBox="0 0 60.3 59.5">
  //       <path fill="#e8762d" d="M28.5 40.2h3.3v-9h8.3V28h-8.3v-9h-3.3v9h-8.2v3.2h8.2z" />
  //       <path fill="#c72037" d="M13.2 53.2H16v-8h7.4v-2.5H16v-8.1h-2.8v8.1H5.8v2.5h7.4z" />
  //       <path fill="#5b879b" d="M44.3 24.3h2.8v-8h7.5v-2.4h-7.5V5.8h-2.8v8.1h-7.4v2.4h7.4z" />
  //       <path fill="#5c6692" d="M29 59.5h2.4v-5.7h5.1v-2.1h-5.1V46H29v5.7h-5v2.1h5z" />
  //       <path fill="#eb9129" d="M13.3 24.3h2.6v-8.1h7.5v-2.3h-7.5V5.8h-2.6v8.1H5.8v2.3h7.5z" />
  //       <path fill="#5c6692" d="M52.8 36.3h2.4v-5.6h5.1v-2.2h-5.1v-5.6h-2.4v5.6h-5v2.2h5z" />
  //       <path
  //         fill="#1f457e"
  //         fillRule="evenodd"
  //         d="M44.3 53.2h2.8v-8h7.5v-2.5h-7.5v-8.1h-2.8v8.1h-7.4v2.5h7.4z"
  //         clipRule="evenodd"
  //       />
  //       <path
  //         fill="#7199a6"
  //         d="M36.1 7.2V5.5h-5V0h-1.8v5.5h-5v1.7h5v5.5h1.8V7.2zM5 35.9h1.8v-5.5h5v-1.7h-5v-5.4H5v5.4H0v1.8l5-.1z"
  //       />
  //     </svg>
  //   ),
  // },
  {
    name: 'mapboxIso',
    text: 'MapBox - Polygon',
    category: 'Utilities',
    toolType: 'defaultNode',
    tooltip: 'Returns a polygon based on a set of coordinates',
    defaultAnchor: 'dataframe',
    icon: (
      <svg
        className="h-3.5 w-3.5"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M8 2H16L22 8V16L16 22H8L2 16V8L8 2Z" stroke="currentColor" strokeWidth="1.5" />
      </svg>
    ),
  },
  {
    name: 'mapboxDir',
    text: 'MapBox - Directions',
    category: 'Utilities',
    toolType: 'defaultNode',
    tooltip:
      'Returns a duration, distance and GeoJSON-formatted string based on a two sets of coordinates',
    defaultAnchor: 'dataframe',
    icon: (
      <svg
        className="w-3.5 h-3.5"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M7.5 1.5L4 5H22V12H2V19H20L16.5 22.5" stroke="black" strokeWidth="1.5" />
      </svg>
    ),
  },
  // {
  //   name: 'stop',
  //   text: 'Stop',
  //   category: 'Utilities',
  //   toolType: 'outputNode',
  //   tooltip: 'Stops the workflow if there is any data flowing to this tool',
  //   defaultAnchor: 'dataframe',
  //   executeDisableDownstream: true,
  //   icon: <XIcon className="w-3.5 h-3.5" />,
  //   description: function () {
  //     console.log('test');
  //   },
  // },
];

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import ModalHeader from 'components/Layout/ModalHeader';
import TextDescInput from 'components/Inputs/TextDesc';
import SecondaryBtn from 'components/Buttons/SecondaryBtn';
import DeleteBtn from 'components/Buttons/DeleteBtn';

import {
  getUsersAction,
  getGroupsAction,
  userProfileAction,
  sendInviteAction,
} from 'redux/actions/auth';
import {
  createGroupAction,
  deleteGroupAction,
  removeUsersFromGroupAction,
  addUsersFromGroupAction,
} from 'redux/actions/group';

import { deleteTokenAction, getTokensAction, updateSettingsAction, createTokenAction } from 'redux/actions/profile';
import { MoonIcon, SunIcon } from '@heroicons/react/solid';
import RegularInputTemplate from 'components/Layout/RegularInputTemplate';
import PrimaryBtn from 'components/Buttons/PrimaryBtn';
import TertiaryBtn from 'components/Buttons/TertiaryBtn';
import TemplatePopover from 'components/Buttons/TemplatePopover';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import TableAccordion from 'components/Accordions/TableAccordion';
import BasicModal from './BasicModal';
import SelectCard from 'components/Cards/SelectCard';
import DeleteModal from './DeleteModal';
import RadixTooltip from 'components/Tooltips/RadixTooltip';
import { InformationCircleIcon } from '@heroicons/react/solid';
import { changeUserCreatorAction, deleteUserAction } from 'redux/actions/user';

const SettingsModal = ({
  user,
  name,
  email,
  NameOnClick,
  EMOnClick,
  PWOnClick,
  SecOnClick,
  LogoutOnClick,
  theme,
  changeTheme,
}) => {
  let menu = [
    { id: '1', label: 'Profile' },
    { id: '2', label: 'Users' },
    { id: '3', label: 'Groups' },
    { id: '4', label: 'Notifications' },
    { id: '5', label: 'API Tokens' },
    { id: '6', label: 'Settings' },
  ];

  let [activeTab, setActiveTab] = useState(menu[0].id);

  const [tokens, setTokens] = useState([]);
  const [tokenName, setTokenName] = useState("");
  const [tempToken, setTempToken] = useState(null);


  const dispatch = useDispatch();

  const { users, groups } = useSelector(({ platform }) => {
    return {
      users: platform.users,
      groups: platform.groups,
    };
  });

  const isAdmin =
    user?.groups?.includes('admin') || user?.groups?.includes('super-admin') ? true : false;

  const onInviteChange = (name, value) => {
    setInvite({ ...invite, [name]: value });
  };

  const updateData = async () => {
    dispatch(getUsersAction());
    dispatch(getGroupsAction());
    setTokens(await dispatch(getTokensAction()).unwrap());
  };

  const removeUser = async () => {
    if (removerUserGroup) {
      const result = await dispatch(
        removeUsersFromGroupAction({ group_id: selectedGroup, users: [removerUserGroup.id] })
      );
      if (result) {
        await updateData();
      }
    }
    return true;
  };

  const addUsersHandler = (userId, checked) => {
    if (checked) {
      addUsersObject[userId] = userId;
    } else {
      delete addUsersObject[userId];
    }
  };

  const addUsers = async () => {
    if (selectedGroup) {
      const result = await dispatch(
        addUsersFromGroupAction({ group_id: selectedGroup, users: Object.keys(addUsersObject) })
      );
      if (result) {
        await updateData();
        setAddUserModal(false);
        setAddUsersObject({});
      }
    }
    return true;
  };

  const createGroupHandler = async () => {
    const result = await dispatch(
      createGroupAction({ group_name: newGroupName, users: Object.keys(addUsersObject) })
    );
    if (result) {
      await updateData();
      setNewGroupName(null);
      setAddUsersObject({});
      setCreateGroup(false);
    }
    return true;
  };

  const deleteGroupHandler = async () => {
    const result = await dispatch(deleteGroupAction(selectedGroup));
    if (result) {
      await updateData();
      setDeleteGroup(false);
    }
    return true;
  };

  const deleteUserHandler = async () => {
    const result = await dispatch(deleteUserAction({ userId: deleteUser.id }));
    if (result) {
      await updateData();
      setDeleteGroup(false);
    }
    return true;
  };

  const deleteTokenHandler = async (tokenId) => {
    const result = await dispatch(deleteTokenAction({ tokenId }));
    if (result) {
      await updateData();
    }
    return true;
  };

  const createTokenHandler = async () => {
    const result = await dispatch(createTokenAction({ name: tokenName })).unwrap();
    setTokenName("");
    setTempToken(result);
    await updateData();
    return true;
  };

  useEffect(() => {
    function componentDidMount() {
      updateData();
    }
    componentDidMount();
  }, []);

  const [notify_error_connection, setNotify_error_connection] = useState(
    user?.settings?.find((item) => item.key == 'notify_error_connection')?.['value'] == '1'
      ? true
      : false
  );
  const [notify_error_model, setNotify_error_model] = useState(
    user?.settings?.find((item) => item.key == 'notify_error_model')?.['value'] == '1'
      ? true
      : false
  );

  const [displayVerticalScroll, setDisplayVerticalScroll] = useState(localStorage.getItem('displayScroll') == "True" ? true : false);

  const changeVerticalScroll = (value) => {
    if (value) {
      setDisplayVerticalScroll(true)
      localStorage.setItem('displayScroll', "True")
    } else {
      setDisplayVerticalScroll(false)
      localStorage.setItem('displayScroll', "False")
    }
  }

  const [invite, setInvite] = useState({ name: '', email: '' });
  const [addUserModal, setAddUserModal] = useState(false);
  const [deleteGroup, setDeleteGroup] = useState(false);
  const [createGroup, setCreateGroup] = useState(false);
  const [removerUserGroup, setRemoverUserGroup] = useState(false);
  const [addUsersObject, setAddUsersObject] = useState({});
  const [newGroupName, setNewGroupName] = useState(null);

  const updateNotificationSettings = async () => {
    const settings = [
      { key: 'notify_error_connection', value: notify_error_connection },
      { key: 'notify_error_model', value: notify_error_model },
    ];
    await dispatch(updateSettingsAction(settings));
    await dispatch(userProfileAction());
  };

  const sendInvite = async () => {
    const result = await dispatch(sendInviteAction(invite));
    if (result) {
      await updateData();
      setInvite({ name: '', email: '' });
    }
    return true;
  };

  const changeCreatorHandler = async (e, userId) => {
    const result = await dispatch(
      changeUserCreatorAction({ userId, data: { value: e.target.checked } })
    );
    if (result) {
      await updateData();
      return true;
    }
    return false;
  };

  const [selectedGroup, setSelectedGroup] = useState(null);
  const [deleteUser, setDeleteUser] = useState(null);

  const activeGroup = groups.find((group) => group.id === selectedGroup);

  return (
    <>
      {tempToken && (
        <BasicModal
          open={tempToken}
          bgClasses="!backdrop-blur-none"
          classes="w-[600px] h-max"
          onClose={() => setTempToken(null)}
          content={
            <div className="p-8">
              <ModalHeader header="Successfully created token" content={<p>Save your token as this is the last time it is displayed</p>} />
              <div className="mt-5" />
              <div className="flex flex-row justify-between items-center">
                <TextDescInput
                  read={true}
                  type="text"
                  tooltip="This is your token - make sure to save it as it will be the last time it is displayed"
                  header="Token"
                  name="token"
                  readValue={tempToken}
                  withtooltip={"false"}
                />
              </div>
            </div>
          }
        />
      )}
      {createGroup && (
        <BasicModal
          open={createGroup}
          bgClasses="!backdrop-blur-none"
          classes="w-[600px] h-max"
          onClose={() => setCreateGroup(false)}
          content={
            <div className="p-8">
              <ModalHeader header="Create new group" />
              <div className="mt-5" />
              <TextDescInput
                type="text"
                header="Name"
                subheader="Input your group name"
                placeholder={'fancy group'}
                value={newGroupName}
                onChange={setNewGroupName}
              />
              <div className="mt-8" />
              <RegularInputTemplate
                header="Add users"
                subheader="Choose the users that you want to add to the group"
              >
                <div className="gap-4 grid grid-cols-2">
                  {users.map((user, userIndex) => (
                    <SelectCard
                      key={userIndex}
                      onChange={(e) => addUsersHandler(user.id, e)}
                      label={
                        <div className="flex flex-col">
                          <p>{user.name}</p>
                          <p className="font-normal opacity-80">{user.email}</p>
                        </div>
                      }
                    />
                  ))}
                </div>
              </RegularInputTemplate>
              <div className="bottom-0 bg-zinc-50 dark:bg-zinc-900 dark:border-zinc-700 border-t mt-5 -mx-8 -mb-8 p-3 flex flex-row-reverse">
                <div className="mr-2 space-x-3">
                  <SecondaryBtn text="Cancel" onClick={() => setCreateGroup(false)} />
                  <PrimaryBtn text="Create group" onClick={() => createGroupHandler()} />
                </div>
              </div>
            </div>
          }
        />
      )}
      {addUserModal && (
        <BasicModal
          open={addUserModal}
          bgClasses="!backdrop-blur-none"
          classes="w-[600px] h-max"
          onClose={() => setAddUserModal(false)}
          content={
            <div className="p-8">
              <ModalHeader
                header={
                  selectedGroup
                    ? `Add users to ${activeGroup.title} group`
                    : 'No matching group found'
                }
              />

              <div className="my-3 select-none block text-sm font-normal text-zinc-700  dark:text-zinc-50">
                <a
                  className="hover:underline underline-offset-[1.5px] cursor-pointer opacity-50 hover:opacity-100 duration-150"
                  onClick={() => { }}
                >
                  Select all
                </a>
                <a className="opacity-50"> | </a>
                <a
                  className="hover:underline underline-offset-[1.5px] cursor-pointer opacity-50 hover:opacity-100 duration-150"
                  onClick={() => { }}
                >
                  Deselect all
                </a>
              </div>
              <div className="gap-4 grid grid-cols-2">
                {users
                  .filter(
                    (user) => !user.groups.some((userGroup) => userGroup.id === selectedGroup)
                  )
                  .map((user, userIndex) => (
                    <SelectCard
                      key={userIndex}
                      onChange={(e) => addUsersHandler(user.id, e)}
                      value={user.id}
                      label={
                        <div className="flex flex-col">
                          <p>{user.name}</p>
                          <p className="font-normal opacity-80">{user.email}</p>
                        </div>
                      }
                    />
                  ))}
              </div>
              <div className="bottom-0 bg-zinc-50 dark:bg-zinc-900 dark:border-zinc-700 border-t mt-5 -mx-8 -mb-8 p-3 flex flex-row-reverse">
                <div className="mr-2 space-x-3">
                  <SecondaryBtn text="Cancel" onClick={() => setAddUserModal(false)} />
                  <PrimaryBtn text="Add user(s)" onClick={() => addUsers()} />
                </div>
              </div>
            </div>
          }
        />
      )}
      {deleteUser && (
        <DeleteModal
          open
          onClose={() => setDeleteUser(false)}
          header={`Delete User`}
          description={`Are you sure you want to delete this user? The user will be permanently removed. This action cannot be undone.`}
          deletetxt="Delete user"
          deletepress={() => deleteUserHandler()}
        />
      )}
      {deleteGroup && (
        <DeleteModal
          open
          onClose={() => setDeleteGroup(false)}
          header={`Delete ${activeGroup.title} Group`}
          description={`Are you sure you want to delete the ${activeGroup.title}? The group will be permanently removed. This action cannot be undone.`}
          deletetxt="Delete group"
          deletepress={() => deleteGroupHandler()}
        />
      )}
      {removerUserGroup && (
        <DeleteModal
          open
          onClose={() => setRemoverUserGroup(false)}
          header={`Remove user`}
          description={`Are you sure you want to delete this users from the group?`}
          deletetxt="Remove user"
          deletepress={() => removeUser(selectedGroup)}
        />
      )}
      <div className="flex flex-row min-h-[600px]">
        <div className="min-h-full w-[200px] z-10 bg-zinc-100 dark:bg-zinc-800 dark:border-zinc-600 border-r border-zinc-200 flex flex-col items-start pt-8 p-4 ">
          {menu.map((tab) => (
            <button
              className={`${activeTab === tab.id
                ? 'text-zinc-700 bg-zinc-200 dark:bg-zinc-700 dark:text-zinc-50 font-medium  border-zinc-300/60 dark:border-zinc-500/60'
                : 'text-zinc-500 dark:text-zinc-200 dark:hover:bg-zinc-600/40 hover:bg-zinc-200/40 border-transparent'
                } mb-2 w-full text-left py-[5px] px-2 rounded border text-sm duration-150 transition`}
              onClick={() => setActiveTab(tab.id)}
              key={tab.id}
            >
              {tab.label}
            </button>
          ))}
        </div>
        <div className="mx-8 mt-8 mb-16 h-full w-full overflow-scroll">
          {activeTab == '1' && (
            <div className="h-full w-full relative">
              <ModalHeader header="Profile" />
              <div className="my-5 space-y-8 w-full divide-y divide-zinc-200 dark:divide-zinc-600">
                <div className="flex flex-row items-center">
                  <RegularInputTemplate
                    header="Change appearance"
                    subheader="Switch between light and dark mode"
                  />
                  <button
                    id="theme-button"
                    className={` ${theme == 'light' ? 'bg-zinc-800' : 'bg-white'
                      } border-2  w-fit p-2 rounded-full`}
                    onClick={changeTheme}
                  >
                    {theme == 'light' ? (
                      <MoonIcon className="w-4 h-4 text-zinc-50 drop-shadow-[0_5px_5px_rgba(255,255,255,0.5)]" />
                    ) : (
                      <SunIcon className="w-4 h-4 text-yellow-500 drop-shadow-[0_5px_5px_rgba(252,211,77,1)]" />
                    )}
                  </button>
                </div>
                <div className="pt-5 flex gap-3 flex-col items-start">
                  <TextDescInput
                    read={true}
                    type="text"
                    header="Name"
                    name="name"
                    readValue={name}
                    withtooltip={false}
                    subheader="Used to display ownership of data assets (and the cool avatar in the sidebar 😎)"
                    withdesc={true}
                  />
                  <span
                    onClick={NameOnClick}
                    className="duration-150 transform transition pl-1 -mt-1 text-xs hover:cursor-pointer hover:underline decoration-2 decoration-[#5DA6FC] underline-offset-[3px] dark:text-zinc-400 dark:hover:text-zinc-300 text-zinc-500 hover:text-zinc-400  font-normal"
                  >
                    Change name
                  </span>
                </div>
                <div className="pt-5 flex gap-3 flex-col items-start">
                  <TextDescInput
                    read={true}
                    type="text"
                    header="Email"
                    name="email"
                    readValue={email}
                    withtooltip={false}
                    subheader="Change the email you use to log in"
                    withdesc={true}
                  />
                  <span
                    onClick={EMOnClick}
                    className="duration-150 transform transition pl-1 -mt-1 text-xs hover:cursor-pointer hover:underline decoration-2 decoration-[#5DA6FC] underline-offset-[3px] dark:text-zinc-400 dark:hover:text-zinc-300 text-zinc-500 hover:text-zinc-400  font-normal"
                  >
                    Change email
                  </span>
                </div>
                <div className="pt-5 flex flex-col ">
                  <label
                    htmlFor="id"
                    className="block text-sm font-medium text-zinc-700 dark:text-zinc-50"
                  >
                    Password
                  </label>
                  <p
                    className="mt-0.5 text-[13px] font-light text-zinc-500 dark:text-zinc-200"
                    id="id description"
                  >
                    Update your password
                  </p>
                  <div className="mt-2 mx-0.5">
                    <SecondaryBtn text="Change Password" onClick={PWOnClick} />
                  </div>
                </div>
              </div>
              <div className="right-0 bottom-0  fixed w-[calc(100%-170px)]  bg-zinc-50 dark:bg-zinc-900  px-8 py-3  border-t dark:border-zinc-600 flex flex-row-reverse gap-2">
                <DeleteBtn text="Log out" onClick={LogoutOnClick} />

                <SecondaryBtn text="Cancel" onClick={SecOnClick} />
              </div>
            </div>
          )}
          {activeTab == '2' && (
            <div>
              <div className="">
                <ModalHeader header="Users" />
                {isAdmin && (
                  <div className="mt-5">
                    <RegularInputTemplate
                      header="Invite a team member"
                      subheader="Send an invite to a new team member"
                    />

                    <div className="mt-5 flex items-end flex-row gap-4">
                      <div className="w-full">
                        <TextDescInput
                          type="text"
                          name="inviteName"
                          header="Name"
                          placeholder={"Someone's name"}
                          onChange={(val) => onInviteChange('name', val)}
                          value={invite.name}
                        />
                      </div>
                      <div className="w-full">
                        <TextDescInput
                          type="text"
                          name="inviteEmail"
                          header="Email"
                          placeholder={'thomas@less.tech'}
                          onChange={(val) => onInviteChange('email', val)}
                          value={invite.email}
                        />
                      </div>
                      <div className="h-fit inline-flex mb-0.5">
                        <PrimaryBtn text="Invite" onClick={sendInvite} />
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {isAdmin && <div className="h-[1px] my-8 bg-zinc-200/80 w-full" />}
              <div className={`py-0 ${!isAdmin && 'mt-5'}`}>
                <RegularInputTemplate
                  header="Users"
                  subheader={`View and edit your ${users.length} team members`}
                />
                <div className="mt-6 border-b pb-1.5 flex gap-3 flex-row items-center justify-start px-3.5">
                  <p className="text-xs  text-zinc-400 uppercase tracking-wide dark:text-zinc-50 w-52">
                    User
                  </p>
                  <p className="text-xs  text-zinc-400 uppercase tracking-wide dark:text-zinc-50 w-52">
                    Email
                  </p>
                  <p className="text-xs  text-zinc-400 uppercase tracking-wide dark:text-zinc-50 w-52">
                    Last login
                  </p>
                  <div className="text-xs flex flex-row justify-center items-start gap-3 text-zinc-400 uppercase text-center tracking-wide dark:text-zinc-50 w-32">
                    <p>Creator</p>

                    <RadixTooltip
                      tooltipText={
                        'Creators can create new folders, connections and models and run tools in existing models (only where they have permissions to access).'
                      }
                    >
                      <InformationCircleIcon
                        className={`h-4 w-4 text-zinc-700 dark:text-zinc-200 opacity-60`}
                        aria-hidden="true"
                      />
                    </RadixTooltip>
                  </div>
                  <p className="text-xs text-center text-zinc-400 uppercase tracking-wide dark:text-zinc-50 w-32">
                    Groups
                  </p>
                </div>
                <div className="h-fit divide-y  divide-zinc-100  px-3.5 overflow-scroll border-b  max-h-[40vh]">
                  {users.map((user, index) => (
                    <div
                      className="flex justify-between flex-row  items-center py-2 rounded-sm"
                      key={index}
                    >
                      <div className="flex gap-3 flex-row items-center justify-center">
                        <p className="text-xs text-zinc-600 dark:text-zinc-50 w-52">{user.name}</p>
                        <p className="text-xs mt-0.5 text-zinc-600 dark:text-zinc-300 w-52  line-clamp-1">
                          {user.email}
                        </p>
                        <p className="text-xs mt-0.5  text-zinc-600 dark:text-zinc-300 w-52">
                          {user.last_login_at}
                        </p>
                        <div className="text-xs items-center flex flex-row justify-center mt-0.5 w-32">
                          <input
                            className="h-4 w-4 rounded border-zinc-300 text-[#5DA6FC] focus:ring-[#5DA6FC]"
                            checked={user.creator} //user.creator
                            onChange={(e) => changeCreatorHandler(e, user.id)}
                            type="checkbox"
                            disabled={!isAdmin}
                          />
                        </div>
                        <div className="w-32 flex flex-row items-center justify-center">
                          <TemplatePopover
                            btnText={
                              <div className="gap-1 flex flex-row items-center justify-center">
                                <p>
                                  {user.groups.length > 0
                                    ? user.groups.length > 1
                                      ? user.groups.length
                                      : user.groups[0].title
                                    : '0'}
                                </p>
                                <ChevronDownIcon className="text-zinc-400 h-auto w-3" />
                              </div>
                            }
                            btnClasses={
                              'w-fit hover:bg-zinc-100  hover:cursor-pointer rounded flex flex-row items-center justify-center px-2 py-1 gap-0.5 text-xs text-zinc-600 dark:text-zinc-300'
                            }
                          >
                            <div className="bg-zinc-100 px-3 py-3 flex flex-col gap-2 rounded border border-zinc-200 shadow-lg shadow-zinc-600/10">
                              {user.groups.map((group) => {
                                return (
                                  <div className="text-xs" key={group.id}>
                                    <p className="text-zinc-600">{group.title}</p>
                                    <p className="text-zinc-400">{group?.users.length} members</p>
                                  </div>
                                );
                              })}
                            </div>
                          </TemplatePopover>
                        </div>
                      </div>
                      {isAdmin && (
                        <div className="h-fit inline-flex ">
                          <TertiaryBtn
                            classes="!text-xs !border !text-zinc-400 hover:!bg-red-50 hover:!text-red-400 hover:!border-red-400"
                            text="Delete"
                            onClick={() => setDeleteUser(user)}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          {activeTab == '3' && (
            <div className="">
              <ModalHeader
                classes="flex flex-row justify-between items-center"
                header="Groups"
                content={
                  isAdmin && (
                    <PrimaryBtn onClick={() => setCreateGroup(true)} text="Create new group" />
                  )
                }
              />

              <div className="overflow-scroll max-h-[78vh] -mr-5 pr-5 ">
                {groups.map((group, index) => (
                  <>
                    <TableAccordion
                      key={index}
                      childrenClasses="items-center flex flex-col w-full"
                      headerClasses="mt-5"
                      header={group.title}
                      open={false}
                      description={`| members: ${group.users.length}`}
                    >
                      <div className="divide-y divide-zinc-100 overflow-scroll max-h-[30vh] px-2 border-b border-t w-full ">
                        {users
                          .filter((user) =>
                            user.groups.some((userGroup) => userGroup.id === group.id)
                          )
                          .map((user, userIndex) => (
                            <div
                              className="py-1 text-xs flex flex-row justify-between items-center w-full"
                              key={userIndex}
                            >
                              <div className="grid grid-cols-2 gap-10 ">
                                <p className="w-44">{user.name}</p>
                                <p className="w-44">{user.email}</p>
                              </div>
                              {isAdmin && (
                                <TertiaryBtn
                                  onClick={() => {
                                    setRemoverUserGroup(user), setSelectedGroup(group.id);
                                  }}
                                  classes="!text-xs !border !text-zinc-400 hover:!bg-zinc-50"
                                  text="Remove from group"
                                />
                              )}
                            </div>
                          ))}
                      </div>
                      <div className="flex flex-row gap-4">
                        {isAdmin && (
                          <>
                            <TertiaryBtn
                              classes="mt-2 !text-xs !border !text-zinc-400 hover:!bg-zinc-50"
                              onClick={() => (setAddUserModal(true), setSelectedGroup(group.id))}
                              text="Add users"
                            />
                            <TertiaryBtn
                              classes="mt-2 !text-xs !border !text-zinc-400 hover:!bg-red-50 hover:!text-red-400 hover:!border-red-400"
                              onClick={() => (setDeleteGroup(true), setSelectedGroup(group.id))}
                              text="Delete group"
                            />
                          </>
                        )}
                      </div>
                    </TableAccordion>
                  </>
                ))}
              </div>
            </div>
          )}
          {activeTab == '4' && (
            <div className="h-full w-full relative">
              <div className="w-full">
                <ModalHeader header="Notifications" />
                <div className="mt-5" />
                <RegularInputTemplate
                  header="Email notifications"
                  subheader="Receive email notification based on certain events. You will receive notifications to the email address that you use to log in to Less and you will only receive notifications for connectors/models that you own or where you initiated a manual refresh."
                />
                <div className="space-y-5 mt-5 ">
                  <div className="flex flex-row justify-start items-start ">
                    <input
                      id={`1`}
                      aria-describedby="comments-description"
                      name="hejsa"
                      checked={notify_error_connection}
                      type="checkbox"
                      className="h-4 w-4 rounded border-zinc-300 dark:border-zinc-400 text-[#5DA6FC] focus:ring-[#5DA6FC]"
                      onChange={(e) => setNotify_error_connection(e.target.checked)}
                    />
                    <div className="pl-3 -mt-[4px]">
                      <p className="text-sm text-zinc-700 dark:text-zinc-50">
                        Connection notifications
                      </p>
                      <p className="text-xs text-zinc-500 dark:text-zinc-300/80">
                        Receive an immediate email notification whenever a connection fails
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-row justify-start items-start">
                    <input
                      id={`1`}
                      aria-describedby="comments-description"
                      name="hejsa"
                      checked={notify_error_model}
                      type="checkbox"
                      className="h-4 w-4 rounded border-zinc-300 dark:border-zinc-400 text-[#5DA6FC] focus:ring-[#5DA6FC]"
                      onChange={(e) => setNotify_error_model(e.target.checked)}
                    />
                    <div className="pl-3 -mt-[4px]">
                      <p className="text-sm text-zinc-700 dark:text-zinc-50">Model notifications</p>
                      <p className="text-xs text-zinc-500 dark:text-zinc-300/80">
                        Receive an immediate email notification whenever a model fails
                      </p>
                    </div>
                  </div>
                  {/* <div className="flex flex-row justify-start items-start">
                <input
                  id={`1`}
                  aria-describedby="comments-description"
                  name="hejsa"
                  //checked={!num?.[item.name]?.['disabled'] || false}
                  type="checkbox"
                  className="h-4 w-4 rounded border-zinc-300 dark:border-zinc-400 text-[#5DA6FC] focus:ring-[#5DA6FC]"
                //onChange={() => handleCheckboxChange(item.name)}
                />
                <div className="pl-3 -mt-[4px]">
                  <p className="text-sm text-zinc-700 dark:text-zinc-50">Daily Activity Summary</p>
                  <p className="text-xs text-zinc-500 dark:text-zinc-300/80">
                    Receive a daily email summary of failed connections and models
                  </p>
                </div>
              </div> */}
                </div>
              </div>
              <div className="right-0 bottom-0 fixed w-[calc(100%-165px)] bg-zinc-50 dark:bg-zinc-900 dark:border-zinc-700 border-t px-3 rounded-br-lg mt-5 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <div className="mr-2 space-x-3">
                  <SecondaryBtn text="Cancel" onClick={SecOnClick} />
                  <PrimaryBtn
                    text="Save notification settings"
                    onClick={() => updateNotificationSettings()}
                  />
                </div>
              </div>
            </div>
          )}
          {activeTab == '5' && isAdmin && (
            <div className="w-full flex flex-col ">
              <ModalHeader header="API Tokens" content={<p>Use API tokens to interact with Less from other systems.</p>} />

              <div className="space-y-8  divide-y divide-zinc-200 dark:divide-zinc-600">
                <div className="mt-8 flex  flex-row gap-4 items-end justify-between">
                  <div className="w-[80%]">
                    <TextDescInput
                      type="text"
                      name="name"
                      header="Create New Token"
                      placeholder={'Zapier Token'}
                      onChange={(val) => setTokenName(val)}
                    />
                  </div>
                  <PrimaryBtn text="Create token" onClick={() => { createTokenHandler() }} />
                </div>
                {tokens.length > 0 && (
                  <div className={`pt-8`}>
                    <RegularInputTemplate
                      header="Existing tokens"

                    />
                    <div className="mt-6 border-b pb-1.5 flex gap-3 flex-row items-center justify-start px-3.5">
                      <p className="text-xs  text-zinc-400 uppercase tracking-wide dark:text-zinc-50 w-52">
                        Name
                      </p>
                      <p className="text-xs  text-zinc-400 uppercase tracking-wide dark:text-zinc-50 w-52">
                        Created At
                      </p>
                      <p className="text-xs  text-zinc-400 uppercase tracking-wide dark:text-zinc-50 w-52">
                        Last Used at
                      </p>
                    </div>
                    <div className="h-fit divide-y  divide-zinc-100  px-3.5 overflow-scroll border-b  max-h-[40vh]">
                      {tokens.map((token, index) => (
                        <div
                          className="flex justify-between flex-row  items-center py-2 rounded-sm"
                          key={index}
                        >
                          <div className="flex gap-3 flex-row items-center justify-center">
                            <p className="text-xs text-zinc-600 dark:text-zinc-50 w-52">{token.name}</p>
                            <p className="text-xs mt-0.5 text-zinc-600 dark:text-zinc-300 w-52  line-clamp-1">
                              {token.created_at}
                            </p>
                            <p className="text-xs mt-0.5  text-zinc-600 dark:text-zinc-300 w-52">
                              {token.last_used_at}
                            </p>
                          </div>
                          <div className="h-fit inline-flex ">
                            <TertiaryBtn
                              classes="!text-xs !border !text-zinc-400 hover:!bg-red-50 hover:!text-red-400 hover:!border-red-400"
                              text="Delete"
                              onClick={() => deleteTokenHandler(token.id)}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {activeTab == '6' && (
            <div className="h-full w-full relative">
              <div className="w-full">
                <ModalHeader header="Settings" />
                <div className="mt-5" />
                <RegularInputTemplate
                  header="Local platform settings"
                  subheader="Settings on platform for the device you are using"
                />
                <div className="space-y-5 mt-5 ">
                  <div className="flex flex-row justify-start items-start">
                    <input
                      id={`1`}
                      aria-describedby="comments-description"
                      name="hejsa"
                      checked={displayVerticalScroll}
                      type="checkbox"
                      className="h-4 w-4 rounded border-zinc-300 dark:border-zinc-400 text-[#5DA6FC] focus:ring-[#5DA6FC]"
                      onChange={(e) => changeVerticalScroll(e.target.checked)}
                    />
                    <div className="pl-3 -mt-[4px]">
                      <p className="text-sm text-zinc-700 dark:text-zinc-50">Display vertical scrollbars</p>
                      <p className="text-xs text-zinc-500 dark:text-zinc-300/80">
                        Displays vertical scroolbars where normally hidden
                      </p>
                    </div>
                  </div>
                  {/* <div className="flex flex-row justify-start items-start">
                <input
                  id={`1`}
                  aria-describedby="comments-description"
                  name="hejsa"
                  //checked={!num?.[item.name]?.['disabled'] || false}
                  type="checkbox"
                  className="h-4 w-4 rounded border-zinc-300 dark:border-zinc-400 text-[#5DA6FC] focus:ring-[#5DA6FC]"
                //onChange={() => handleCheckboxChange(item.name)}
                />
                <div className="pl-3 -mt-[4px]">
                  <p className="text-sm text-zinc-700 dark:text-zinc-50">Daily Activity Summary</p>
                  <p className="text-xs text-zinc-500 dark:text-zinc-300/80">
                    Receive a daily email summary of failed connections and models
                  </p>
                </div>
              </div> */}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

SettingsModal.propTypes = {
  user: PropTypes.object,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  SecOnClick: PropTypes.func,
  NameOnClick: PropTypes.func,
  EMOnClick: PropTypes.func,
  PWOnClick: PropTypes.func,
  LogoutOnClick: PropTypes.func,
  theme: PropTypes.string,
  changeTheme: PropTypes.func,
};

SettingsModal.defaultProps = {
  SecOnClick: () => { },
  NameOnClick: () => { },
  EMOnClick: () => { },
  PWOnClick: () => { },
};

export default SettingsModal;

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BtnDropdown from 'components/Dropdowns/BtnDropdown';
import WhitelistGrid from 'components/Grids/WhitelistGrid';
import MyDatabasesGrid from 'components/Grids/MyDatabasesGrid';
import Heading from 'components/Layout/PageHeading';
import BasicModal from 'components/Modals/BasicModal';
import DeleteModal from 'components/Modals/DeleteModal';
import DatabaseNew from 'components/Modals/DatabaseNew';
import DatabaseLink from 'components/Modals/DatabaseLink';
import ViewCredentials from 'components/Modals/ViewCredentials';
import {
  getAllDatabasesAction,
  deleteDatabaseAction,
  createDatabaseAction,
} from 'redux/actions/database';
import { DatabaseIcon, LinkIcon } from '@heroicons/react/solid';
import {
  getResourceRulesetCollectionAction,
  setResourceRulesetAction,
} from 'redux/actions/ruleset';
import SharingModal from 'components/Modals/SharingModal';

export default function Database() {
  const dispatch = useDispatch();
  const { databases, roles, groups, user } = useSelector(({ databases, platform, auth }) => {
    return {
      databases: databases.databases,
      roles: platform.roles,
      groups: platform.groups,
      user: auth.user,

    };
  });

  const [activeDB, setActiveDB] = useState({});
  const [whitelistModal, setWhitelistModal] = useState(false);
  const [openDelete, setDeleteModal] = useState(false);
  const [credentialsModal, setCredentialModal] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [linkModal, setLinkModal] = useState(false);
  const [sharing, setSharing] = useState(false);
  const [rulesets, setRulesets] = useState([]);
  const [activeObject, setActiveObject] = useState();

  const isAdmin = user?.groups?.includes('admin') || user?.groups?.includes('super-admin') ? true : false;

  async function shareObject(objectType, object) {
    const rules = await dispatch(
      getResourceRulesetCollectionAction({ objectType: objectType, objectId: object.id })
    ).unwrap();
    setRulesets(rules);
    setSharing(true);
    setActiveObject({ objectType, object });
  }

  const combinedArray = groups.map((group) => {
    const matchingSet = rulesets.find((set) => set?.entity_id === group.id) || null;
    return {
      ...group,
      set: matchingSet,
    };
  });

  const changeRule = (group, role) => {
    if (rulesets.find((set) => set.entity_id == group.id)?.ability_set_id != role) {
      const newRuleset = {
        id: group?.set?.id,
        entity_id: group.id,
        ability_set_id: role,
        new: true,
      };
      setRulesets([...rulesets.filter((set) => set.entity_id != group.id), newRuleset]);
    }
  };

  const submitRules = async () => {
    const newRules = rulesets.filter((set) => set.new);
    if (newRules.length > 0) {
      await dispatch(
        setResourceRulesetAction({
          objectId: activeObject.object.id,
          objectType: activeObject.objectType,
          rules: newRules,
        })
      )
        .unwrap()
        .then(async () => {
          // handle result here
          setSharing(false);
          await dispatch(getAllDatabasesAction());
        })
        .catch((e) => {
          // handle error here
          console.log('ERROR HERE', e);
        });
    }
  };

  useEffect(function componentDidMount() {
    dispatch(getAllDatabasesAction());
  }, []);

  function createDatabase() {
    setCreateModal(true);
  }
  function closeCreateDatabase() {
    setCreateModal(false);
  }

  function linkDatabase() {
    setLinkModal(true);
  }
  function closeLinkDatabase() {
    setLinkModal(false);
  }

  function credentials(database) {
    setCredentialModal(true);
    setActiveDB(database);
  }

  function closeCredentials() {
    setCredentialModal(false);
  }

  function whitelistIP(database) {
    setWhitelistModal(true);
    setActiveDB(database);
  }

  function closeWhitelistIP() {
    setWhitelistModal(false);
    setActiveDB({});
  }

  function deleteDatabase(database) {
    setDeleteModal(true);
    setActiveDB(database);
  }

  function closeDeleteDatabase() {
    setDeleteModal(false);
    setActiveDB({});
  }

  const deleteDB = async (database) => {
    await dispatch(deleteDatabaseAction(database.id));
    await dispatch(getAllDatabasesAction());
    closeDeleteDatabase();
  };

  const createBtnItems = [
    {
      header: 'New Database',
      ability: isAdmin,
      icon: (
        <DatabaseIcon
          className="mr-2 h-4 w-4 text-zinc-400 group-hover:text-zinc-500 dark:text-zinc-300 dark:group-hover:text-zinc-200"
          aria-hidden="true"
        />
      ),
      action: () => {
        createDatabase();
      },
    },
    {
      header: 'Link Database',
      ability: isAdmin,
      icon: (
        <LinkIcon
          className="mr-2 h-4 w-4 text-zinc-400 group-hover:text-zinc-500 dark:text-zinc-300 dark:group-hover:text-zinc-200"
          aria-hidden="true"
        />
      ),
      action: () => {
        linkDatabase();
      },
    },
  ];

  const createDatabaseHandler = async (data = {}) => {
    await dispatch(createDatabaseAction({ data: data }));
    // reset selected connection ID for memoized value refresh
    // setConnectionId(0);
    // hide connection input dialog upon successful connection
    // setConnectionInputDialog(false);
    // refresh connections table to show new entry
    await dispatch(getAllDatabasesAction());
    closeCreateDatabase();
    closeLinkDatabase();
  };

  return (
    <div className="h-auto w-auto mx-auto m-3 rounded-lg p-3">
      {createModal && (
        <BasicModal
          open={createModal}
          classes="w-[600px] 2xl:w-[700px] min-w-lg"
          onClose={() => closeCreateDatabase()}
          content={
            <DatabaseNew
              onClick={(state) => createDatabaseHandler(state)}
              onClose={() => closeCreateDatabase()}
            />
          }
        />
      )}
      {linkModal && (
        <BasicModal
          open={linkModal}
          classes="w-[600px] 2xl:w-[700px] min-w-lg"
          onClose={() => closeLinkDatabase()}
          content={
            <DatabaseLink
              onClick={(state) => createDatabaseHandler(state)}
              onClose={() => closeLinkDatabase()}
            />
          }
        />
      )}
      {openDelete && (
        <DeleteModal
          open={openDelete}
          onClose={() => closeDeleteDatabase()}
          header="Delete Database"
          description="Are you sure you want to delete this database? The database will be permanently removed. This action cannot be undone."
          deletetxt="Delete database"
          deletepress={() => deleteDB(activeDB)}
        />
      )}
      {whitelistModal && (
        <BasicModal
          open={whitelistModal}
          classes="w-[600px] h-max 2xl:w-[700px]"
          onClose={() => closeWhitelistIP()}
          content={<WhitelistGrid database={activeDB} />}
        />
      )}
      {credentialsModal && (
        <BasicModal
          open={credentialsModal}
          classes="w-[600px] h-max 2xl:w-[700px]"
          onClose={() => closeCredentials()}
          content={<ViewCredentials database={activeDB} />}
        />
      )}
      {sharing && (
        <SharingModal
          sharing={sharing}
          setSharing={setSharing}
          activeObject={activeObject}
          roles={roles}
          combinedArray={combinedArray}
          submitRules={submitRules}
          changeRule={changeRule}
        />
      )}
      <div className="mb-2">
        <Heading
          header="Storage"
          button={<BtnDropdown btnHeader="Create" items={createBtnItems} />}
        />
      </div>
      <div className="py-2">
        <MyDatabasesGrid
          {...{
            databases: databases,
            credentials,
            whitelistIP,
            deleteDatabase,
            shareObject,
            emptyMessage: 'You do now have access to see any databases',
          }}
        />
      </div>
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
// components
import TableRow from 'components/Tables/TableRow';
//import PrimaryTooltip from 'components/Tooltips/PrimaryTooltip';
import StatusBadge from 'components/Badges/StatusBadge';
import ContextMenu from 'components/Dropdowns/ContextMenu';
// utils
import { lastToString, frequencyToString } from 'utils/datetimeHumanzie';
import OnlyIconBtn from 'components/Buttons/OnlyIconBtn';
import { StopIcon } from '@heroicons/react/solid';
import RadixTooltip from 'components/Tooltips/RadixTooltip';
import { calcConnectionStatus } from 'utils/calcStatus';
import ShareWith from 'components/Tables/TableContent/SharedWith';
import { stopTaskAction } from 'redux/actions/task';

const AssetRowConnection = ({ connection, menu, rowClick, refreshFolders }) => {

  const [status, setStatus] = useState(
    calcConnectionStatus(connection)
  );

  useEffect(
    function componentDidMount() {
      setStatus(calcConnectionStatus(connection))
    },
    [connection]
  );

  const dispatch = useDispatch();

  const stopTask = async (taskId) => {
    await dispatch(stopTaskAction({ object_type: 'Connection', object_id: connection.id, task_id: taskId.last_task.id }));
  };

  return (
    <tr className="" onClick={rowClick}>
      <td className="text-center w-[100px]">
        <StatusBadge status={status} errorMessage={connection.errorMessage} />
      </td>
      <TableRow
        content={
          <div className="flex flex-row items-center justify-start gap-3">
            <img
              className="ml-1 p-1.5 w-[31.8px] h-[31.8px] duration-400 relative rounded-md bg-zinc-50 border border-zinc-200 dark:border-zinc-700"
              src={connection?.connector?.logo}
              alt={connection?.connector?.name}
            />
            <span className="flex flex-col">
              <p className="text-xs font-medium text-zinc-700 dark:text-zinc-50 max-w-[250px] truncate">
                {connection.name?.length > 37 ? (
                  <RadixTooltip
                    childClasses="max-w-[250px] truncate !cursor-pointer"
                    tooltipText={connection.name}
                  >
                    {connection.name}
                  </RadixTooltip>
                ) : (
                  connection.name
                )}
              </p>
              <p className="text-xs text-zinc-500 dark:text-zinc-200 font-normal">
                Updated:{' '}
                <a className="font-normal">{lastToString(connection?.last_task?.executed_at || connection?.last_task?.created_at)}</a>
              </p>
            </span>
          </div>
        }
      />

      {/* {connection.description?.length > 50 ? (
        <TableRow
          classes={'cursor-pointer underline hover:text-zinc-600'}
          content={
            <div className="justify-left flex">
              <PrimaryTooltip tooltipText={connection.description} bottom="110%">
                {connection.description.substring(0, 50) + '...'}
              </PrimaryTooltip>
            </div>
          }
        />
      ) : (
        <TableRow content={<div className="justify-left flex">{connection.description} </div>} />
      )} */}
      {/* <TableRow content={nextToString(connection?.next_schedule?.runtime)} /> */}
      <TableRow content={
        <ShareWith rulesets={connection.rulesets} />
      } />
      <TableRow content={frequencyToString(connection.schedule)} />
      <TableRow content={connection?.user?.name} />
      <TableRow
        content={
          status === 100 && connection?.last_task?.id ? (
            <div className="justify-center flex items-center w-[125px] ">
              <OnlyIconBtn
                icon={<StopIcon className="h-4 w-4" aria-hidden="true" />}
                onClick={() => {
                  setStatus(500);
                  stopTask(connection);
                  refreshFolders();
                }}
              />
            </div>
          ) : (
            ''
          )
        }
      />
      <TableRow
        content={
          <div className="justify-center flex flex-row flex-1 gap-2 w-[75]">
            <ContextMenu
              options={menu}
              object={{
                ...connection,
                setPending: () => {
                  setStatus(100);
                  refreshFolders();
                },
              }}
            />
          </div>
        }
      />
    </tr>
  );
};

AssetRowConnection.propTypes = {
  menu: PropTypes.any,
  refreshFolders: PropTypes.func,
  connection: PropTypes.shape({
    connector: PropTypes.object,
    description: PropTypes.string,
    errorMessage: PropTypes.any,
    schedule: PropTypes.any,
    id: PropTypes.any,
    in_queue: PropTypes.array,
    last_task: PropTypes.shape({
      created_at: PropTypes.any,
      executed_at: PropTypes.any,
      run_code: PropTypes.any,
      id: PropTypes.any,
    }),
    rulesets: PropTypes.array,
    next_schedule: PropTypes.shape({
      runtime: PropTypes.any,
    }),
    name: PropTypes.string,
    user: PropTypes.shape({
      name: PropTypes.any,
    }),
  }),
  statusDef: PropTypes.any,
  rowClick: PropTypes.any,
};

export default AssetRowConnection;

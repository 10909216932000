export default function folderFlatten(folders, folderIdToRemove = -1) {
  return folders.reduce((flattened, folder) => {
    if (folder.id === folderIdToRemove) {
      return flattened;
    }
    flattened.push({ label: folder.name, value: folder.id });
    if (folder.children.length) {
      return flattened.concat(folderFlatten(folder.children, folderIdToRemove));
    }
    return flattened;
  }, []);
}

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CheckCircleIcon } from '@heroicons/react/outline';

import { removeNotification } from 'redux/reducers/notification';
import Notification from './Notification';

const NotificationManager = () => {
  const dispatch = useDispatch();
  const { notifications } = useSelector(({ notifications }) => {
    return notifications;
  });

  const closeHandler = (id) => {
    dispatch(removeNotification(id));
  };

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="z-[100] fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          {notifications.map((item) => (
            <Notification
              key={item.id}
              timestamp={item.id}
              open
              icon={<CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />}
              {...item}
              onClose={closeHandler}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default NotificationManager;

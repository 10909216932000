import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  runModellingExportService,
  runModellingGetSessionService,
  runModellingClearCacheService,
} from 'services/modelling';

// First, create the thunk

export const runModellingExportAction = createAsyncThunk(
  'modelling/export',
  async ({ modelId, toolId, format }) => {
    const response = await runModellingExportService(modelId, toolId, format);
    return response;
  }
);

export const runModellingGetSessionAction = createAsyncThunk(
  'modelling/getSession',
  async ({ uuid }) => {
    const response = await runModellingGetSessionService(uuid);
    return response;
  }
);

export const runModellingClearCacheAction = createAsyncThunk(
  'modelling/ClearCache',
  async ({ uuid }) => {
    const response = await runModellingClearCacheService(uuid);
    return response;
  }
);
// Then, handle actions in your reducer

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import CubeTransparentIcon from '@heroicons/react/outline/CubeTransparentIcon';
// components
import TableRow from 'components/Tables/TableRow';
import StatusBadge from 'components/Badges/StatusBadge';
import ContextMenu from 'components/Dropdowns/ContextMenu';
// utils
import { lastToString, frequencyToString } from 'utils/datetimeHumanzie';
import OnlyIconBtn from 'components/Buttons/OnlyIconBtn';
import { StopIcon } from '@heroicons/react/solid';
import RadixTooltip from 'components/Tooltips/RadixTooltip';
import { calcModelStatus } from 'utils/calcStatus';
import ShareWith from 'components/Tables/TableContent/SharedWith';
import { stopTaskAction } from 'redux/actions/task';

const AssetRowDatamodel = ({ model, menu, rowClick, refreshFolders }) => {


  const [status, setStatus] = useState(
    calcModelStatus(model)
  );

  useEffect(
    function componentDidMount() {
      setStatus(calcModelStatus(model))
    },
    [model]
  );

  const dispatch = useDispatch();

  const stopTask = async (taskId) => {
    await dispatch(stopTaskAction({ object_type: 'Datamodel', object_id: model.id, task_id: taskId.last_task.id }));
  };

  return (
    <tr className="hover:bg-zinc-100 dark:hover:bg-zinc-800 cursor-pointer" onClick={rowClick}>
      <td className="text-center w-[100px]">
        <StatusBadge status={status} errorMessage={model.errorMessage} />
      </td>
      <TableRow
        content={
          <div className="flex flex-row items-center justify-start gap-3">
            <div className="ml-1 duration-400 relative rounded-md p-1.5 bg-zinc-50 dark:bg-zinc-800 dark:border-zinc-700 border border-zinc-200">
              <CubeTransparentIcon className="h-[18px] w-[18px]" />
            </div>
            <span className="flex flex-col">
              <p className="text-xs font-medium text-zinc-700 dark:text-zinc-50 max-w-[250px] truncate">
                {model.name.length > 37 ? (
                  <RadixTooltip
                    childClasses="max-w-[250px] truncate !cursor-pointer"
                    tooltipText={model.name}
                  >
                    {model.name}
                  </RadixTooltip>
                ) : (
                  model.name
                )}
              </p>
              <p className="text-xs text-zinc-500 dark:text-zinc-200 font-normal">
                Updated:{' '}
                <a className="font-normal">{lastToString(model?.last_task?.executed_at || model?.last_task?.created_at)}</a>
              </p>
            </span>
          </div>
        }
      />

      <TableRow content={
        <ShareWith rulesets={model.rulesets} />
      } />
      {/* <TableRow content={nextToString(model?.next_schedule?.runtime)} /> */}
      <TableRow content={frequencyToString(model.schedule)} />
      <TableRow content={model?.user?.name} />
      <TableRow
        content={
          status === 100 && model?.last_task?.id ? (
            <div className="justify-center flex items-center w-[125px] ">
              <OnlyIconBtn
                icon={<StopIcon className="h-4 w-4" aria-hidden="true" />}
                onClick={() => {
                  setStatus(500);
                  stopTask(model);
                  refreshFolders();
                }}
              />
            </div>
          ) : (
            ''
          )
        }
      />
      <TableRow
        content={
          <div className="justify-center flex flex-row flex-1 gap-2 w-[75]">
            <ContextMenu
              options={menu}
              object={{
                ...model,
                setPending: () => {
                  setStatus(100);
                  refreshFolders();
                },
              }}
            />
          </div>
        }
      />
    </tr>
  );
};

AssetRowDatamodel.propTypes = {
  menu: PropTypes.any,
  refreshFolders: PropTypes.func,
  model: PropTypes.shape({
    description: PropTypes.string,
    errorMessage: PropTypes.any,
    schedule: PropTypes.any,
    id: PropTypes.any,
    in_queue: PropTypes.array,
    rulesets: PropTypes.array,
    last_task: PropTypes.shape({
      created_at: PropTypes.any,
      executed_at: PropTypes.any,
      run_code: PropTypes.any,
      id: PropTypes.any,
    }),
    next_schedule: PropTypes.shape({
      runtime: PropTypes.any,
    }),
    name: PropTypes.string,
    user: PropTypes.shape({
      name: PropTypes.any,
    }),
  }),
  statusDef: PropTypes.any,
  rowClick: PropTypes.any,
};

export default AssetRowDatamodel;

import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import LogGrid from 'components/Grids/LogGrid';
import ModalHeader from 'components/Layout/ModalHeader';
import { XIcon } from '@heroicons/react/solid';
import { frequencyToString } from 'utils/datetimeHumanzie';
import { toDate, toReadDate } from 'utils/unitConversion';
import { getTasksAction } from 'redux/actions/task';

const LogModal = ({ activeConnection, onClose }) => {

  const [tasks, setTasks] = useState([]);
  const dispatch = useDispatch();

  const getTasks = async () => {
    const result = await dispatch(getTasksAction({ object_type: activeConnection.type, object_id: activeConnection.id })).unwrap();
    setTasks(result)
  };

  useEffect(() => {
    getTasks();
  }, []);

  return (
    <div className="p-8">
      <button
        type="button"
        className="p-1 absolute top-[4px] right-[4px] rounded-md bg-none text-zinc-400 hover:text-zinc-500 dark:text-zinc-300 dark:hover:text-zinc-200  focus:outline-none focus:ring-0 transition duration-100"
        onClick={onClose}
      >
        <XIcon className="h-5 w-5" aria-hidden="true" />
      </button>

      <ModalHeader
        header={activeConnection?.name}
        content={
          <p className="mt-2 max-w-4xl text-sm align-baseline">View you connection log history</p>
        }
      />
      <div className="mt-3 flex gap-3 flex-row justify-between ">
        {/* {kpis.map((info, index) => ( */}
        <div
          className="border px-3 py-1.5 rounded border-zinc-100 h-full bg-zinc-50 dark:bg-zinc-700 dark:border-zinc-600 "
          key="owner"
        >
          <p className="text-xs  text-zinc-500 dark:text-zinc-200">
            {'Owner'}:{' '}
            <span className="mt-[1px] text-xs font-medium text-zinc-500 dark:text-zinc-200">
              {activeConnection?.user?.name}
            </span>
          </p>
        </div>
        <div
          className="border px-3 py-1.5 rounded border-zinc-100 h-full bg-zinc-50 dark:bg-zinc-700 dark:border-zinc-600 "
          key="frequency"
        >
          <p className="text-xs  text-zinc-500 dark:text-zinc-200">
            {'Update frequency'}:{' '}
            <span className="mt-[1px] text-xs font-medium text-zinc-500 dark:text-zinc-200">
              {frequencyToString(activeConnection?.schedule)}
            </span>
          </p>
        </div>
        <div
          className="border px-3 py-1.5 rounded border-zinc-100 h-full bg-zinc-50 dark:bg-zinc-700 dark:border-zinc-600 "
          key="lastupdate"
        >
          <p className="text-xs  text-zinc-500 dark:text-zinc-200">
            {'Last update'}:{' '}
            <span className="mt-[1px] text-xs font-medium text-zinc-500 dark:text-zinc-200">
              {activeConnection?.last_task?.executed_at || activeConnection?.last_task?.created_at}
            </span>
          </p>
        </div>
        <div
          className="border px-3 py-1.5 rounded border-zinc-100 h-full bg-zinc-50 dark:bg-zinc-700 dark:border-zinc-600 "
          key="lastNumberOfExtractedRows"
        >
          <p className="text-xs  text-zinc-500 dark:text-zinc-200">
            {'Last number of extracted rows'}:{' '}
            <span className="mt-[1px] text-xs font-medium text-zinc-500 dark:text-zinc-200">
              {activeConnection?.last_task?.records_retrieved}
            </span>
          </p>
        </div>
        <div
          className="border px-3 py-1.5 rounded border-zinc-100 h-full bg-zinc-50 dark:bg-zinc-700 dark:border-zinc-600 "
          key="lastDuration"
        >
          <p className="text-xs  text-zinc-500 dark:text-zinc-200">
            {'Last duration'}:{' '}
            <span className="mt-[1px] text-xs font-medium text-zinc-500 dark:text-zinc-200">
              {toDate(activeConnection?.last_task?.finished_at) - toDate(activeConnection?.last_task?.executed_at || activeConnection?.last_task?.created_at) < -3600
                ? ''
                : toReadDate(toDate(activeConnection?.last_task?.finished_at) - toDate(activeConnection?.last_task?.executed_at || activeConnection?.last_task?.created_at))}
            </span>
          </p>
        </div>
        {/* ))} */}
      </div>
      <div className="mt-3 px-2 py-3  bg-zinc-50 dark:bg-zinc-700 rounded border border-zinc-100">
        <LogGrid log={tasks} connection={activeConnection} />
      </div>
    </div>
  );
};

LogModal.propTypes = {
  activeConnection: PropTypes.object,
  onClose: PropTypes.func,
  kpis: PropTypes.array,
};

LogModal.defaultProps = {
  activeConnection: {},
  onClose: null,
  kpis: [],
};

export default LogModal;

import React, { useState } from 'react';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/solid';
import { Link, useParams } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import { resetPasswordAction } from 'redux/actions/auth';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';

export default function ResetPassword() {
  const { token } = useParams();
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');

  const [state, setState] = useState({
    token: token,
    email: email,
  });

  const onChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const dispatch = useDispatch();
  const onClick = async () => {
    await dispatch(resetPasswordAction(state));
  };

  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);

  const toggle = () => {
    setOpen(!open);
  };
  const toggleConfirm = () => {
    setOpenConfirm(!openConfirm);
  };

  // Form Validation
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    watch,
  } = useForm({
    mode: 'onTouched',
  });
  let password = watch('password');

  return (
    <div className="min-h-full h-screen bg-[#13151D] flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="w-full max-w-screen-md border-[0.5px] border-zinc-600 bg-gradient-to-br from-slate-800 via-transparent to-zinc-900 px-28 py-16 rounded-2xl space-y-8">
        <div>
          <img
            className="mx-auto h-20 w-auto opacity-50"
            src="https://less-logos.s3.eu-central-1.amazonaws.com/random/logoLight.svg"
            alt="Logo"
          />
          <h2 className="mt-12 -mb-3 text-center text-2xl font-extrabold text-slate-300">
            Reset password
          </h2>
          <p className="text-slate-400 font-medium text-center mt-5 text-sm">
            Enter a new password
          </p>
        </div>
        <div id="results">
          <form className="space-y-5" onSubmit={handleSubmit(onClick)}>
            <div>
              <div className="mt-1">
                <input
                  id="token"
                  name="token"
                  type="hidden"
                  autoComplete="off"
                  value={state?.token}
                  required
                  className="appearance-none text-slate-200 bg-[#13151D] block w-full px-3 py-2 border-1 border-slate-600 rounded-md shadow-sm placeholder-slate-500 focus:outline-none focus:ring-gray-300 focus:border-gray-300 sm:text-sm"
                />
              </div>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="hidden"
                  autoComplete="off"
                  value={state?.token}
                  required
                  className="appearance-none text-slate-200 bg-[#13151D] block w-full px-3 py-2 border-1 border-slate-600 rounded-md shadow-sm placeholder-slate-500 focus:outline-none focus:ring-gray-300 focus:border-gray-300 sm:text-sm"
                />
              </div>
            </div>
            <div>
              <label htmlFor="password" className="block text-sm font-medium text-slate-300">
                New password
              </label>
              <div className="relative mt-1">
                <input
                  {...register('password', {
                    required: 'Password is required',
                    minLength: { value: 8, message: 'Password must be more than 8 characters' },
                  })}
                  id="password1"
                  name="password"
                  type={open === false ? 'password' : 'text'}
                  autoComplete="off"
                  onChange={onChange}
                  required
                  className="appearance-none text-slate-200 bg-[#13151D] block w-full px-3 py-2 border-1 border-slate-600 rounded-md shadow-sm placeholder-slate-500 focus:outline-none focus:ring-gray-300 focus:border-gray-300 sm:text-sm"
                />
                <div className="absolute top-2.5 right-8">
                  {open === false ? (
                    <EyeIcon
                      onClick={toggle}
                      className="h-5 w-5 text-slate-300"
                      aria-hidden="true"
                    />
                  ) : (
                    <EyeOffIcon
                      onClick={toggle}
                      className="h-5 w-5 text-slate-300"
                      aria-hidden="true"
                    />
                  )}
                </div>
              </div>
              {errors.password && (
                <p className="text-sm rounded-lg mt-4 py-3 px-4  font-medium bg-red-200 text-red-800">
                  {errors.password?.message}
                </p>
              )}
            </div>
            <div>
              <label
                htmlFor="confirm-password"
                className="block text-sm font-medium text-slate-300"
              >
                Confirm new password
              </label>
              <div className="relative mt-1">
                <input
                  {...register('password_confirmation', {
                    required: 'You must repeat your password',
                    validate: (value) => value === password || 'The passwords do not match',
                  })}
                  id="password_confirmation2"
                  name="password_confirmation"
                  type={openConfirm === false ? 'password' : 'text'}
                  autoComplete="off"
                  onChange={onChange}
                  required
                  className="appearance-none text-slate-200 bg-[#13151D] block w-full px-3 py-2 border-1 border-slate-600 rounded-md shadow-sm placeholder-slate-500 focus:outline-none focus:ring-gray-300 focus:border-gray-300 sm:text-sm"
                />
                <div className="absolute top-2.5 right-8">
                  {openConfirm === false ? (
                    <EyeIcon
                      onClick={toggleConfirm}
                      className="h-5 w-5 text-slate-300"
                      aria-hidden="true"
                    />
                  ) : (
                    <EyeOffIcon
                      onClick={toggleConfirm}
                      className="h-5 w-5 text-slate-300"
                      aria-hidden="true"
                    />
                  )}
                </div>
              </div>
              {errors.password_confirmation && !errors.password && (
                <p className="text-sm rounded-lg mt-4 py-3 px-4  font-medium bg-red-200 text-red-800">
                  {errors.password_confirmation?.message}
                </p>
              )}
              {isSubmitSuccessful && (
                <Link to="/login">
                  <p className="cursor-pointer text-sm rounded-lg mt-4 py-3 px-4  font-medium bg-green-200 text-green-800">
                    Your password has successfully been change. Click this box to go to the login
                    screen.
                  </p>
                </Link>
              )}
            </div>
            <div className="flex flex-col flex-1 items-center w-5/12 justify-center space-y-2 mx-auto">
              <button
                type="submit"
                className="group w-full mb-2 relative inline-flex items-center justify-center rounded-full p-[1px] font-normal"
              >
                {' '}
                <div className="absolute h-full shadow-slate-800 shadow-lg w-full rounded-full bg-gradient-to-tr from-[#5DA6FC] via-[#FFC6BA] to-[#ff5478] group-hover:bg-gradient-to-br group-hover:from-gray-200 group-hover:to-gray-200"></div>
                <div className="relative inline-flex w-full justify-center items-center rounded-full bg-slate-900 px-5 py-2 transition-all ease-out delay-50 duration-300  group-hover:bg-gray-200">
                  <p className="text-center text-gray-100 font-semibold text-base group-hover:text-[#13151D]">
                    Change Password
                  </p>
                </div>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

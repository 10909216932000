import React, { useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LockClosedIcon } from '@heroicons/react/solid';
import { userLoginAction } from 'redux/actions/auth';
import { useJune } from 'utils/useJune';
import { getWelcomeAction } from 'redux/actions/home'
import { env } from '../../env'

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const analytics = useJune('f7aFCim6eVJaVfRf');

  const [state, setState] = React.useState({
    email: '',
    emailError: false,
    passwordError: false,
    password: '',
  });

  /* eslint-disable */
  const { user, auth_type } = useSelector(({ auth, home }) => {
    return {
      user: auth.user,
      auth_type: home.auth
    };
  });
  /* eslint-enable */

  const stateRef = useRef(state);

  const loginUser = async () => {
    try {
      const requestData = await dispatch(
        userLoginAction({
          username: stateRef.current?.email,
          password: stateRef.current?.password,
        })
      );
      identity(
        requestData.payload.user?.id,
        requestData.payload.user?.name,
        requestData.payload.user?.email,
        new URL(env.REACT_APP_API_URL)?.host
      );
      trackCompany();

      trackLogin(requestData.payload.user?.id);
      navigate('/', { replace: true });
    } catch {
      //console.log('error’, error);
    }
  };

  const onChange = (e) => {
    const data = { ...state, [e.target.name]: e.target.value };
    stateRef.current = data;
    setState(data);
  };

  const identity = useCallback(
    (id, name, email) => {
      const company = new URL(env.REACT_APP_API_URL)?.host;
      const identifier = `${id}_${company}`;
      if (analytics)
        analytics.identify(identifier, {
          email: email,
          name: name,
          company: company,
        });
    },
    [analytics]
  );

  const trackLogin = useCallback(
    (id) => {
      const company = new URL(env.REACT_APP_API_URL)?.host;
      const identifier = `${id}_${company}`;
      if (analytics)
        analytics.track({
          userId: identifier,
          event: 'Signed In',
          company: company,
          context: {
            groupId: company,
          },
        });
    },
    [analytics]
  );

  const trackCompany = useCallback(() => {
    const comp = new URL(env.REACT_APP_API_URL)?.host;
    if (analytics)
      analytics.group(comp, {
        name: comp,
      });
  }, [analytics]);

  useEffect(() => {
    async function componentDidMount() {
      await dispatch(getWelcomeAction())
    }
    componentDidMount();
    const listener = async (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        loginUser();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);

  let SAMLLogin = false
  let allowBasic = true

  if (auth_type?.auth_method?.type == "SAML") {
    SAMLLogin = true
    if (auth_type?.auth_methods) {
      allowBasic = true
    }
  }



  return (
    <>
      <div className="min-h-full h-screen bg-[#13151D] flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full relative flex flex-col items-center max-w-screen-md border-[0.5px] border-zinc-600 bg-gradient-to-br from-slate-800 via-transparent to-zinc-900 px-28 py-16 rounded-2xl space-y-8">
          <div>
            <img
              className="mx-auto h-20 w-auto opacity-50"
              src="https://less-logos.s3.eu-central-1.amazonaws.com/random/logoLight.svg"
              alt="Logo"
            />
            <h2
              className={`mt-12 ${SAMLLogin === true ? '' : '-mb-3'
                } text-center text-2xl font-extrabold text-slate-300`}
            >
              Sign in to your account
            </h2>
          </div>
          <input type="hidden" name="remember" defaultValue="true" />

          {allowBasic === true && (
            <div className="max-w-[400px] w-[400px]  rounded-md shadow-sm space-y-4">
              <div>
                <label htmlFor="email-address" className="block text-sm font-medium text-slate-300">
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email-address"
                    name="email"
                    type="email"
                    autoComplete="email"
                    onChange={onChange}
                    placeholder="your@email.here"
                    required
                    className="appearance-none text-slate-200 bg-[#13151D] block w-full px-3 py-2 border-1 border-slate-600 rounded-md shadow-sm placeholder-slate-500 focus:outline-none focus:ring-gray-300 focus:border-gray-300 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="password" className="block text-sm font-medium text-slate-300">
                  Password
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    onChange={onChange}
                    placeholder="safepassword123"
                    required
                    className="appearance-none text-slate-200 bg-[#13151D] block w-full px-3 py-2 border-1 border-slate-600 rounded-md shadow-sm placeholder-slate-500 focus:outline-none focus:ring-gray-300 focus:border-gray-300 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <a
                  href="/forgotpassword"
                  className=" text-sm font-medium text-slate-500 hover:text-slate-200"
                >
                  Forgot your password?
                </a>
              </div>
            </div>
          )}
          {allowBasic === true && (
            <div className="flex flex-col flex-1 items-center w-5/12 justify-center space-y-2 mx-auto">
              <button
                onClick={() => {
                  loginUser();
                }}
                className="group w-full mb-2 relative inline-flex items-center justify-center rounded-full p-[1px] font-normal"
              >
                {' '}
                <div className="absolute h-full shadow-slate-800 shadow-lg w-full rounded-full bg-gradient-to-tr from-[#5DA6FC] via-[#FFC6BA] to-[#ff5478] group-hover:bg-gradient-to-br group-hover:from-gray-200 group-hover:to-gray-200"></div>
                <div className="relative inline-flex w-full justify-center items-center rounded-full bg-zinc-900 px-5 py-2 transition-all ease-out delay-50 duration-300  group-hover:bg-gray-200">
                  <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                    <LockClosedIcon
                      className="h-5 w-5 text-gray-100 group-hover:text-[#13151D]"
                      aria-hidden="true"
                    />
                  </span>
                  <p className="text-center text-gray-100 font-semibold text-base group-hover:text-[#13151D]">
                    Login
                  </p>
                </div>
              </button>
            </div>
          )}

          {allowBasic === true && SAMLLogin === true && (
            <div className="bg-slate-500 w-[400px] h-[1px] my-10 ">
              <p className="absolute bg-[#13151D] text-slate-500 px-2  left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                or
              </p>
            </div>
          )}

          {SAMLLogin === true && (
            <button onClick={() => { window.location = auth_type?.auth_method?.url }} className="w-[400px] hover:bg-slate-800/50 duration-150 mt-3 relative text-center font-medium tracking-wide text-slate-200 bg-[#13151D] block px-3 py-2 border border-slate-600 rounded-md shadow-sm placeholder-slate-500 focus:outline-none focus:ring-gray-300 focus:border-gray-300 sm:text-sm">
              Continue with {auth_type?.auth_method?.name}
              <img
                className="absolute top-1/2 -translate-y-1/2 left-3 w-5 h-auto"
                src={auth_type?.auth_method?.logo}
                alt={auth_type?.auth_method?.name}
              />
            </button>
          )}

          <p className="text-slate-500 text-sm">
            Don&apos;t have an account?{' '}
            <a
              className="hover:text-slate-200 text-slate-400 duration-150 underline-offset-4 underline decoration-slate-500 hover:decoration-slate-200 "
              href="mailto:hello@less.tech"
            >
              Contact us.
            </a>
          </p>
          <p className="text-slate-500 text-xs absolute bottom-4">
            By continuing, you agree to our{' '}
            <a
              className="hover:text-slate-200  duration-150 underline-offset-4 underline decoration-slate-500 hover:decoration-slate-200"
              href="https://link.less.tech/terms"
            >
              Terms of Service
            </a>{' '}
            and{' '}
            <a
              className="hover:text-slate-200  duration-150 underline-offset-4 underline decoration-slate-500 hover:decoration-slate-200"
              href="https://link.less.tech/privacy-policy"
            >
              Privacy Policy
            </a>
          </p>
        </div>
      </div>
    </>
  );
}

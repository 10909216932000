import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
// components
import Table from 'components/Tables/Table';
// import withClickToCopy from 'components/Tooltips/ClickToCopy';

import BasicModal from 'components/Modals/BasicModal';
import TableRow from 'components/Tables/TableRow';
import OnlyIconBtn from 'components/Buttons/OnlyIconBtn';
import { ShieldCheckIcon, TrashIcon } from '@heroicons/react/solid';
import IconBtn from 'components/Buttons/IconBtn';
import WhitelistNew from 'components/Modals/WhitelistNew';
import { getUserIpAction } from 'redux/actions/home';
import { getDatabaseWhitelistAction, updateWhitelistAction } from 'redux/actions/database';
import TextDescInput from 'components/Inputs/TextDesc';
import RadixTooltip from 'components/Tooltips/RadixTooltip';

const WhitelistGrid = ({ database }) => {
  const [ip, setIp] = useState('');
  const [whitelistRules, setWhitelistRules] = useState([]);

  const headers = [
    { headerName: 'IP Address', classes: 'pl-3' },
    { headerName: 'Description', classes: '' },
    { headerName: '', classes: '' },
  ];

  const tableBody = whitelistRules.map((range, index) => (
    <tr key={`row-${index}`}>
      <TableRow classes="pl-3" content={range.ip} />
      <TableRow content={range.description} />
      <td>
        <div className="justify-end flex w-full flex-row flex-1 mt-3 items-center whitespace-nowrap">
          <RadixTooltip tooltipText="Delete">
            <OnlyIconBtn
              onClick={() => deleteWhitelistHandler(index)}
              icon={<TrashIcon className="h-5 w-5" aria-hidden="true" />}
            />
          </RadixTooltip>
        </div>
      </td>
    </tr>
  ));

  //   const IPCopy = withClickToCopy('button');

  const [whitelistNew, setWhitelistNewModal] = useState(false);

  function newWhitelist() {
    setWhitelistNewModal(true);
  }

  function closeNewWhitelist() {
    setWhitelistNewModal(false);
  }

  const dispatch = useDispatch();
  useEffect(() => {
    async function fetchData() {
      // You can await here
      const ip = await dispatch(getUserIpAction());
      setIp(ip.payload);
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      // You can await here
      const whitelistRules = await dispatch(getDatabaseWhitelistAction(database.id));
      const ips = (whitelistRules.payload[0].IpRanges || []).map(({ CidrIp, Description }) => ({
        ip: CidrIp,
        description: Description,
      }));
      setWhitelistRules(ips);
    }
    fetchData();
  }, []);

  const updateWhitelistHandler = (data = {}) => {
    const rules = [...whitelistRules, data];
    setWhitelistRules(rules);
    dispatch(updateWhitelistAction({ id: database.id, data: JSON.stringify({ rules: rules }) }));
    closeNewWhitelist();
  };

  const deleteWhitelistHandler = (index) => {
    const rules = whitelistRules;
    rules.splice(index, 1);

    setWhitelistRules([...rules]);

    dispatch(updateWhitelistAction({ id: database.id, data: JSON.stringify({ rules: rules }) }));
  };

  return (
    <div className="p-8 ">
      <BasicModal
        open={whitelistNew}
        classes="w-[600px] 2xl:w-[700px] min-h-full min-w-lg"
        onClose={() => closeNewWhitelist()}
        content={
          <WhitelistNew
            currentIp={ip}
            onClose={() => closeNewWhitelist()}
            onClick={(state) => updateWhitelistHandler(state)}
          />
        }
      />
      <div>
        <h3 className="text-xl leading-6 font-bold text-zinc-700 dark:text-zinc-50">
          Whitelist IP{' '}
        </h3>
        <p className="mt-2 max-w-4xl text-sm align-baseline text-zinc-500 dark:text-zinc-200">
          In the table below you can view the IPs that you have already whitelisted. Click the
          button to whitelist additional IPs.
        </p>
        <p className="mt-4 max-w-4xl text-sm align-baseline text-zinc-500 dark:text-zinc-200">
          Whitelisting an IP means opening up access to a database. You need to whitelist your
          computers IP if you want to access the data stored on this database from a third-party
          system like Excel or Tableau.
        </p>
        <p className="mt-4 max-w-4xl text-sm align-baseline text-zinc-500 dark:text-zinc-200">
          If you need help you can read more in{' '}
          <a
            href="https://resources.less.tech/less-tech/advanced-databases-and-security/whitelisting-of-ips"
            target="_blank"
            rel="noopener noreferrer"
            className="text-sm underline decoration-2 decoration-[#5DA6FC]
                                  underline-offset-[3px] text-zinc-500 dark:text-zinc-200 font-medium "
          >
            our guide
          </a>{' '}
          or{' '}
          <a
            href="https://calendly.com/thomas-less/speak-to-less"
            target="_blank"
            rel="noopener noreferrer"
            className="text-sm underline decoration-2 decoration-[#ff5478]
                                  underline-offset-[3px] text-zinc-500 dark:text-zinc-200 font-medium "
          >
            schedule a call
          </a>{' '}
          with us.
        </p>

        <div className="mt-4 relative flex-1 flex flex-row">
          <div className="absolute opacity-60 h-[1px] w-full bg-gradient-to-tr from-[#5DA6FC] via-[#FFC6BA] to-[#ff5478]"></div>
          <div className="relative bg-white sm:flex sm:items-center sm:justify-between"></div>
        </div>
        <div className="mt-5 flex flex-row flex-1 justify-between items-end">
          <div className="w-3/5">
            <TextDescInput
              read={true}
              type="text"
              withtooltip={true}
              tooltip="This is the IP of the computer your using right now. Check if its in the table before adding again"
              ttmargin="150%"
              header="Your current IP address"
              readValue={ip}
            />
          </div>
          <IconBtn
            text="Whitelist New IP"
            onPress={() => {
              newWhitelist();
            }}
            icon={<ShieldCheckIcon className="h-5 w-5" aria-hidden="true" />}
          />
        </div>
      </div>
      <div className="-mx-2 mt-5">
        <Table headers={headers} tableBody={tableBody} />
      </div>
    </div>
  );
};

WhitelistGrid.propTypes = {
  database: PropTypes.object,
  tables: PropTypes.array,
};

export default WhitelistGrid;
